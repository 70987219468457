var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-menu",
    {
      attrs: { "open-on-click": "", "close-on-content-click": false },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function(ref) {
            var on = ref.on
            return [
              _c(
                _vm.multiLine ? "v-textarea" : "v-text-field",
                _vm._g(
                  {
                    tag: "component",
                    attrs: {
                      value: _vm.$td(_vm.translate),
                      label: _vm.$td(_vm.label),
                      "prepend-icon": _vm.getIcon(_vm.$store.state.locale)
                    }
                  },
                  on
                )
              )
            ]
          }
        }
      ])
    },
    [
      _vm._v(" "),
      _c(
        "v-card",
        [
          _c(
            "v-card-text",
            _vm._l(_vm.$store.state.locales, function(locale, id) {
              return _c(
                "div",
                { key: id },
                [
                  _c("div", [_vm._v(_vm._s(locale.name))]),
                  _vm._v(" "),
                  _c(
                    _vm.editor
                      ? "quill-editor"
                      : _vm.multiLine
                      ? "v-textarea"
                      : "v-text-field",
                    {
                      tag: "component",
                      class: { "mb-2": _vm.editor },
                      attrs: {
                        "hide-details": "",
                        "prepend-icon": _vm.getIcon(id),
                        value: _vm.getTranslate(id)
                      },
                      on: {
                        input: function($event) {
                          return _vm.setTranslate(id, $event)
                        }
                      }
                    }
                  )
                ],
                1
              )
            }),
            0
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }