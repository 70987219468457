<template>
    <div
        :class="{ 'fill-height': fillHeight }"
        v-action.add="addActionClick"
        v-action.search="searchActionClick"
        v-action.export="exportActionClick"
        v-action.refresh="refreshActionClick"
        v-action.import="importActionClick"
    >
        <v-dialog
            v-escape="() => editing && ((editing = false), true)"
            v-model="editing"
            lazy
            max-width="80%"
            content-class="mDialog"
            :scrollable="false"
            persistent
        >
            <v-card v-if="editingItem" height="100%">
                <v-layout column fill-height>
                    <v-card-title class="headline">
                        {{ editingItem[this.mItemKey] ? $t("datatable.edit") : $t("datatable.new") }} {{ $td(data.name) }}
                    </v-card-title>
                    <v-flex style="flex: 1; flex-grow: 1; overflow: auto;">
                        <v-card-text>
                            <data-table-edit>
                                <slot name="editor" :origin="editingOrigin" :item="editingItem" :computed="getComputed(editingItem)" />
                            </data-table-edit>
                        </v-card-text>
                    </v-flex>
                    <v-card-actions>
                        <v-layout row justify-center>
                            <v-flex class="text-xs-center">
                                <v-btn :loading="editLoading" large color="green" @click.prevent.stop="save">
                                    <v-icon>done</v-icon>
                                </v-btn>
                            </v-flex>
                            <v-flex class="text-xs-center">
                                <v-btn :disabled="editLoading" large color="red" @click.prevent.stop="(editing = false), (editingItem = null)">
                                    <v-icon>clear</v-icon>
                                </v-btn>
                            </v-flex>
                        </v-layout>
                    </v-card-actions>
                </v-layout>
            </v-card>
        </v-dialog>

        <v-dialog v-model="exporting" lazy max-width="600px" :scrollable="false" persistent>
            <data-table-export
                v-if="exporting"
                v-model="exporting"
                :data="data"
                :mitems="mitems"
                :query="pagination"
                :exportFilter="exportFilter"
                :cursor="cursor"
                :get="get"
                :waitPending="waitPending"
                :headers="headers"
                :itemKey="mItemKey"
                :selected="mselect && selectedLocal.length ? selectedLocal : null"
            />
        </v-dialog>

        <v-dialog v-escape="() => deleting && ((deleting = false), true)" v-model="deleting" lazy max-width="80%">
            <v-card v-if="deletingItem">
                <v-card-title class="headline"> {{ $t("datatable.delete") }} {{ $td(data.name) }} </v-card-title>
                <v-card-text>
                    <v-list>
                        <v-list-tile v-for="(header, idx) in headers" :key="idx" v-show="!header.hideDelete">
                            <v-list-tile-content>
                                <v-list-tile-title v-t="header.text" />
                                <v-list-tile-sub-title>
                                    <renderer :render="renderItem" :item="deletingItem" :header="header" />
                                </v-list-tile-sub-title>
                            </v-list-tile-content>
                        </v-list-tile>
                    </v-list>
                </v-card-text>
                <v-card-actions>
                    <v-layout row justify-center>
                        <v-flex class="text-xs-center">
                            <v-btn :loading="deleteLoading" large color="red" @click.prevent.stop="deleteItemCore()">
                                <v-icon>delete</v-icon>
                            </v-btn>
                        </v-flex>
                        <v-flex class="text-xs-center">
                            <v-btn :disabled="deleteLoading" large color="grey" @click.prevent.stop="deleting = false">
                                <v-icon>arrow_back</v-icon>
                            </v-btn>
                        </v-flex>
                    </v-layout>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog
            v-escape="() => searching && ((searching = false), true)"
            v-model="searching"
            lazy
            v-if="$vuetify.breakpoint.smAndDown && searchFilter"
        >
            <v-card>
                <v-layout :fill-height="fillHeight" column>
                    <v-toolbar dense>
                        <v-toolbar-title v-t="'datatable.search'" />
                        <v-spacer />
                        <v-btn icon @click="resetFilter"><v-icon>clear</v-icon></v-btn>
                        <v-btn icon @click="applyFilter"><v-icon>filter_list</v-icon></v-btn>
                    </v-toolbar>
                    <v-card-text style="flex-grow: 1; flex: 1">
                        <component :is="fillHeight ? 'perfect-scrollbar' : 'div'" :class="{ 'scroll-area': fillHeight }">
                            <slot name="search" :search="searchFilter" />
                        </component>
                    </v-card-text>
                </v-layout>
            </v-card>
        </v-dialog>

        <v-dialog v-escape="() => batchDeleting && ((batchDeleting = false), true)" v-model="batchDeleting" lazy max-width="80%" persistent>
            <data-table
                v-if="batchDeleting"
                :headers="headers"
                :items="mselected"
                :data="{
                    name: data.name,
                    static: true,
                }"
                no-multi
                no-export
            >
                <v-card-actions slot="extend">
                    <v-progress-linear v-if="batchDeleteLoading" :value="batchDeleteProgress" />
                    <v-layout row justify-center>
                        <v-flex class="text-xs-center">
                            <v-btn :loading="batchDeleteLoading" large color="red" @click.prevent.stop="batchDeleteCore()">
                                <v-icon>delete</v-icon>
                            </v-btn>
                        </v-flex>
                        <v-flex class="text-xs-center">
                            <v-btn :disabled="batchDeleteLoading" large color="grey" @click.prevent.stop="batchDeleting = false">
                                <v-icon>arrow_back</v-icon>
                            </v-btn>
                        </v-flex>
                    </v-layout>
                </v-card-actions>
            </data-table>
        </v-dialog>

        <v-card :class="{ 'fill-height': fillHeight }">
            <v-layout :fill-height="fillHeight" column>
                <v-toolbar dense v-if="!hideToolBar">
                    <slot name="pre-title" />
                    <v-toolbar-title v-td="data.name" />
                    <v-spacer />
                    <slot name="pre-actions" />
                    <b-btn v-if="!noExport" :alt-text="$t('basic.export')" @click="exporting = true">
                        <v-icon>fas fa-file-export</v-icon>
                    </b-btn>
                    <b-btn v-if="search" :alt-text="$t('basic.search')" @click.prevent.stop="beginSearch">
                        <v-icon>search</v-icon>
                    </b-btn>
                    <b-btn v-if="data && !data.static" :alt-text="$t('basic.refresh')" @click.prevent.stop="reload">
                        <v-icon>refresh</v-icon>
                    </b-btn>
                    <b-btn :alt-text="$t('basic.add')" :to="add" v-if="add">
                        <v-icon>add</v-icon>
                    </b-btn>
                    <b-btn :alt-text="$t('basic.add')" v-else-if="actions && !noAdd" @click.prevent.stop="editItem(null)">
                        <v-icon>add</v-icon>
                    </b-btn>
                    <slot name="post-actions" />
                </v-toolbar>
                <v-card-text style="flex-grow: 1; flex: 1; display: flex; flex-direction: column">
                    <v-slide-y-transition>
                        <v-layout fill-height v-show="searching" class="mb-2" v-if="searchFilter && $vuetify.breakpoint.mdAndUp">
                            <v-card :class="{ 'fill-height': fillHeight }" style="width: 100%;">
                                <v-layout :fill-height="fillHeight" column>
                                    <v-toolbar dense>
                                        <v-toolbar-title v-t="'datatable.search'" />
                                        <v-spacer />
                                        <v-btn icon @click="resetFilter"><v-icon>clear</v-icon></v-btn>
                                        <v-btn icon @click="applyFilter"><v-icon>filter_list</v-icon></v-btn>
                                    </v-toolbar>
                                    <v-card-text style="flex-grow: 1; flex: 1">
                                        <component :is="fillHeight ? 'perfect-scrollbar' : 'div'" :class="{ 'scroll-area': fillHeight }">
                                            <slot name="search" :search="searchFilter" :computed="getSearchComputed()" />
                                        </component>
                                    </v-card-text>
                                </v-layout>
                            </v-card>
                        </v-layout>
                    </v-slide-y-transition>
                    <v-layout fill-height>
                        <component style="width: 100%;" :is="fillHeight ? 'perfect-scrollbar' : 'div'" :class="{ 'scroll-area': fillHeight }">
                            <component
                                :is="iterator ? 'v-data-iterator' : 'v-data-table'"
                                :expand="expand"
                                :pagination.sync="pagination"
                                :items="mitems"
                                :headers="[...(actions ? [{ text: '', value: '', sortable: false }] : []), ...headers]"
                                :select-all="mselect"
                                :no-data-text="$t('basic.no_data')"
                                :rows-per-page-text="$t('basic.row_per_page')"
                                disable-initial-sort
                                :item-key="mItemKey"
                                :loading="loading"
                                :total-items="items ? undefined : pagination.total"
                                hide-actions
                                :content-props="contentProps"
                                :content-tag="contentTag"
                                :content-class="contentClass"
                                v-model="mselected"
                            >
                                <template slot="headers" slot-scope="props">
                                    <tr>
                                        <th
                                            v-for="(header, idx) in props.headers"
                                            :key="idx"
                                            :class="{
                                                column: !(idx === 0 && mselect),
                                                active: header.value === pagination.sortBy,
                                                desc: pagination.descending && header.sortable,
                                                asc: !pagination.descending && header.sortable,
                                                sortable: header.sortable,
                                                'text-xs-left': true,
                                            }"
                                            @click="changeSort(header)"
                                        >
                                            <div v-if="idx === 0 && mselect" style="display: flex; flex-direction: row; align-items: center;">
                                                <div class="mr-3">
                                                    <v-checkbox
                                                        :input-value="selectState === 'all'"
                                                        :indeterminate="selectState !== 'none' && selectState !== 'all'"
                                                        primary
                                                        hide-details
                                                        @click.stop="toggleAll"
                                                    ></v-checkbox>
                                                </div>
                                                <template v-if="mselected.length">
                                                    <b-btn
                                                        v-if="!noRemove"
                                                        alt-color="pink"
                                                        :alt-text="$t('basic.delete')"
                                                        class="mx-1"
                                                        @click.prevent.stop="batchDelete(mselected)"
                                                    >
                                                        <v-icon color="pink">delete</v-icon>
                                                    </b-btn>

                                                    <b-btn v-if="!noExport" :alt-text="$t('basic.export')" @click="exporting = true">
                                                        <v-icon>fas fa-file-export</v-icon>
                                                    </b-btn>
                                                </template>
                                            </div>
                                            <v-icon small v-if="header.sortable">arrow_upward</v-icon>
                                            {{ header.text }}
                                        </th>
                                    </tr>
                                </template>

                                <template slot="item" slot-scope="props">
                                    <slot name="item" :item="props.item" :headers="headers" :get="get" :deleteItem="deleteItem"> </slot>
                                </template>
                                <tr
                                    slot="items"
                                    :class="{ clickable }"
                                    @longpress="toggleSelect(props.item)"
                                    @shortpress="clickable ? $emit('pickItem', props.item) : (props.expanded = !props.expanded)"
                                    slot-scope="props"
                                >
                                    <td v-if="actions" style="white-space: nowrap;">
                                        <slot name="actions" :item="props.item">
                                            <v-layout align-content-center align-center>
                                                <v-checkbox
                                                    v-if="mselect"
                                                    v-model="props.selected"
                                                    primary
                                                    hide-details
                                                    style="max-width: 50px;"
                                                    @click.native.stop="void 0"
                                                    :height="20"
                                                ></v-checkbox>
                                                <b-btn
                                                    v-if="!noEdit"
                                                    alt-color="teal"
                                                    :alt-text="$t('basic.edit')"
                                                    class="mx-1"
                                                    :to="
                                                        (disableInlineEdit &&
                                                            `${
                                                                rootPath ? rootPath : $route.path.endsWith('/') ? $route.path : $route.path + '/'
                                                            }edit/${props.item._id}`) ||
                                                            undefined
                                                    "
                                                    @click.prevent.stop="!disableInlineEdit && editItem(props.item)"
                                                >
                                                    <v-icon color="teal">edit</v-icon>
                                                </b-btn>
                                                <b-btn
                                                    v-if="!noEdit && !noClone"
                                                    alt-color="purple"
                                                    :alt-text="$t('basic.clone')"
                                                    class="mx-1"
                                                    :to="
                                                        (disableInlineEdit &&
                                                            `${$route.path.endsWith('/') ? $route.path : $route.path + '/'}edit?clone=${
                                                                props.item._id
                                                            }`) ||
                                                            undefined
                                                    "
                                                    @click.prevent.stop="!disableInlineEdit && editItem(props.item, true)"
                                                >
                                                    <v-icon color="purple">file_copy</v-icon>
                                                </b-btn>
                                                <b-btn
                                                    v-if="!noRemove"
                                                    alt-color="pink"
                                                    :alt-text="$t('basic.delete')"
                                                    class="mx-1"
                                                    @click.prevent.stop="deleteItem(props.item)"
                                                >
                                                    <v-icon color="pink">delete</v-icon>
                                                </b-btn>
                                                <slot name="extraActions" :item="props.item" :editItem="editItem" />
                                            </v-layout>
                                        </slot>
                                    </td>
                                    <td v-for="(header, idx) in headers" :key="idx">
                                        <span>
                                            <renderer :render="renderItem" :item="props.item" :header="header" />
                                        </span>
                                    </td>
                                </tr>
                                <template slot="expand" slot-scope="props">
                                    <slot name="expand" :item="props.item" :computed="getComputed(props.item)" />
                                </template>
                            </component>
                        </component>
                    </v-layout>
                </v-card-text>

                <v-card-actions v-if="paginate && (!autoHidePage || (autoHidePage && pages > 1))">
                    <v-layout row align-center justify-center>
                        <template v-if="cursor">
                            <v-flex style="flex-grow: 1;">
                                <v-btn
                                    @click="setCursor(source.cursors.previous)"
                                    icon
                                    :disabled="!source || !source.cursors || !source.cursors.previous"
                                    ><v-icon>keyboard_arrow_left</v-icon></v-btn
                                >
                                <v-btn @click="setCursor(source.cursors.next)" icon :disabled="!source || !source.cursors || !source.cursors.next"
                                    ><v-icon>keyboard_arrow_right</v-icon></v-btn
                                >
                            </v-flex>
                            <v-combobox
                                v-if="!hideRowsPerPage"
                                style="max-width: 100px;"
                                :label="$t('basic.row_per_page')"
                                hide-details
                                :items="rowsPerPageItems"
                                v-model.number="rowsPerPage"
                            />
                        </template>
                        <template v-else>
                            <v-flex style="flex-grow: 1;">
                                <v-pagination :total-visible="10" v-model="page" style="width: 100%" :length="pages" />
                            </v-flex>
                            <div style="width: 120px;">
                                <v-combobox
                                    v-if="!hideRowsPerPage"
                                    :label="$t('basic.row_per_page')"
                                    hide-details
                                    :items="rowsPerPageItems"
                                    v-model.number="rowsPerPage"
                                />
                            </div>
                            <span style="min-width: 80px; text-align: right;" class="px-2">{{ pageText }}</span>
                        </template>
                    </v-layout>
                </v-card-actions>

                <slot name="extend" />
            </v-layout>
        </v-card>
    </div>
</template>
<script>
import _ from "lodash";
import qs from "qs";
import url from "url";
import Vue from "vue";
import uuid from "uuid/v4";
import fieldDefs from "~/plugins/fieldDefs";
import Renderer from "./renderer";

export default {
    components: {
        Renderer,
        DataTableExport: () => import("./DataTableExport"),
    },
    props: {
        extra: {},
        data: {},
        value: {},
        items: {},
        headers: {},
        expand: {},
        hideToolBar: { type: Boolean, default: false },
        add: {},
        search: { type: Boolean, default: false },
        actions: { type: Boolean },
        default: {},
        defaultSearch: {},
        compute: {},
        addAction: { type: Boolean },
        exportAction: { type: Boolean },
        importAction: { type: Boolean },
        searchAction: { type: Boolean },
        refreshAction: { type: Boolean },
        noExport: { type: Boolean, default: false },
        paginate: { default: 10 },
        hideRowsPerPage: { type: Boolean, default: false },
        autoHidePage: { type: Boolean, default: false },
        disableInlineEdit: { type: Boolean, default: false },

        exportFilter: {},

        rootPath: { type: String },

        noEdit: { type: Boolean, default: false },
        noRemove: { type: Boolean, default: false },
        noClone: { type: Boolean, default: true },
        noAdd: { type: Boolean, default: false },
        noMulti: { type: Boolean, default: false },

        clickable: { type: Boolean, default: false },

        iterator: { type: Boolean, default: false },

        fillHeight: { type: Boolean, default: false },

        contentProps: {},
        contentTag: {},
        contentClass: {},

        select: { type: Boolean, default: false },
        selected: {},

        itemKey: { type: String, default: "" },

        dataData: {},

        cursor: { type: Boolean, default: false },
        searchFields: {},
    },
    data() {
        return {
            mdata: null,
            loading: false,
            session: 0,
            mpagination: null,

            deletingItem: null,
            deleteLoading: false,

            batchDeleting: false,
            batchDeleteLoading: false,
            batchDeleteProgress: 0,

            meditingItem: null,
            editLoading: false,
            editingOrigin: null,
            closedEditing: false,

            fetchItems: null,
            fetchCache: {},
            fetchValue: null,

            searching: false,

            searchFilter: null,

            exporting: false,

            selectedLocal: [],
        };
    },
    watch: {
        data(d, od) {
            if (!_.isEqual(d, od)) {
                this.mdata = null;
                this.searching = false;
            }
        },
        extra: {
            deep: true,
            handler(d, od) {
                if (!_.isEqual(d, od)) {
                    this.mdata = null;
                    this.searching = false;
                }
            },
        },
    },
    computed: {
        mItemKey() {
            if (this.itemKey) return this.itemKey;
            if (this.data.subpath) {
                return this.data.path ? "_id" : "id";
            } else if (this.data.path) {
                return "_id";
            }
            return "id";
        },
        mvalue() {
            return this.value || this.fetchValue;
        },
        mitems() {
            const items = this.items || (this.data.subpath && _.get(this.mvalue, this.data.subpath)) || (this.source && this.source.data) || [];
            return this.data.static && this.data.filter ? items.filter((it) => _.every(this.data.filter, (v, k) => it[k] === v)) : items;
        },
        page: {
            get() {
                return this.pagination.page;
            },
            set(page) {
                this.pagination = _.merge({}, this.pagination, { page });
            },
        },
        pages() {
            return (this.pagination && Math.ceil(this.pagination.total / this.pagination.rowsPerPage)) || 0;
        },
        source() {
            return this.data ? this.mdata || this.data.data : null;
        },
        def() {
            return this.data && this.data.path && fieldDefs[this.data.path];
        },
        editing: {
            get() {
                return !!this.editingItem;
            },
            set(v) {
                if (v && !this.editingItem) {
                    console.warn("No editing item");
                } else if (!v) this.editingItem = null;
            },
        },
        editingItem: {
            get() {
                return this.closedEditing ? this.meditingItem : this.data && this.data.editingItem;
            },
            set(val) {
                this.meditingItem = val;
                this.closedEditing = true;
                if (this.data.query) {
                    const u = url.parse(window.location.toString());
                    const query = qs.parse(u.query) || {};
                    query[this.data.query] = _.merge(query[this.data.query], {
                        editor: val && val._id,
                    });
                    if (!query[this.data.query].editor) {
                        delete query[this.data.query].editor;
                    }
                    window.history.replaceState(null, null, url.resolve(this.$route.fullPath, "?" + qs.stringify(query)));
                }
            },
        },
        deleting: {
            get() {
                return !!this.deletingItem;
            },
            set(v) {
                if (v && !this.deletingItem) {
                    console.warn("No editing item");
                } else if (!v) this.deletingItem = null;
            },
        },
        pagination: {
            get() {
                if (this.data.static || !this.data.path) {
                    return (
                        this.mpagination || {
                            rowsPerPage: this.paginate ? this.paginate : -1,
                            page: 1,
                            total: this.mitems.length,
                            sortBy: this.data.sortBy || null,
                            descending: this.data.descending || false,
                            currentFilter: null,
                        }
                    );
                }
                if (!this.source) {
                    return {
                        rowsPerPage: this.paginate ? this.paginate : -1,
                        sortBy: this.data.sortBy,
                        descending: this.data.descending || false,
                    };
                }
                return {
                    rowsPerPage: !this.paginate ? -1 : this.source.limit,
                    page: this.cursor || !this.paginate ? 1 : Math.floor(this.source.skip / this.source.limit) + 1,
                    total: this.cursor ? 999 : this.source.total,
                    cursor: this.cursor ? this.source.skip : null,
                    sortBy: this.source.sortBy,
                    descending: this.source.descending,
                    currentFilter: this.source.currentFilter,
                };
            },
            set(pagination) {
                return this.setPagination(pagination);
            },
        },
        rowsPerPage: {
            get() {
                return this.pagination.rowsPerPage;
            },
            set(val) {
                this.pagination = { ...this.pagination, rowsPerPage: val };
            },
        },
        rowsPerPageItems() {
            return [5, 10, 20, 100];
        },
        pageText() {
            if (!this.paginate) return "";
            const p = this.pagination;
            if (!p.total) return "-";
            return `${(p.page - 1) * p.rowsPerPage + 1}-${Math.min(p.total, p.page * p.rowsPerPage)}/${p.total}`;
        },
        mselected: {
            get() {
                return (this.select ? this.selected : this.selectedLocal) || [];
            },
            set(val) {
                if (this.select) {
                    this.$emit("update:selected", val);
                } else {
                    this.selectedLocal = val;
                }
            },
        },
        mselect: {
            get() {
                return this.select || (this.mselected.length > 0 && !this.noMulti);
            },
        },
        selectState() {
            const selected = this.mselected;
            const dict = _.fromPairs(_.map(this.mitems, (it) => [_.get(it, this.mItemKey), true]));
            let num = 0;
            _.each(selected, (it) => {
                if (dict[_.get(it, this.mItemKey)]) {
                    num++;
                }
            });
            if (selected.length === this.pagination.total) return "all";
            else if (num === this.mitems.length) return "pageAll";
            else if (num) return "pageSome";
            else if (selected.length) return "some";
            else return "none";
        },
    },
    methods: {
        beginSearch() {
            if (!this.searchFilter) {
                this.resetFilter();
            }
            this.searching = !this.searching;
        },

        resetFilter() {
            this.searchFilter = _.cloneDeep(this.defaultSearch || {});
        },

        applyFilter() {
            return this.setPagination({ ...this.pagination, page: 1, currentFilter: _.cloneDeep(this.searchFilter) }, true);
        },

        setPagination(pagination, force) {
            if (force) return this.setPaginationCore(pagination, force);
            if (this._settingPagiationTask && _.isEqual(this._settingPagiation, pagination)) {
                return this._settingPagiationTask;
            }
            this._settingPagiation = pagination;
            return (this._settingPagiationTask = this.setPaginationCore(pagination, force));
        },

        async setPaginationCore(pagination, force) {
            try {
                const q = _.pick(
                    pagination,
                    ...(!this.cursor
                        ? ["page", "rowsPerPage", "sortBy", "descending", "total", "currentFilter"]
                        : ["rowsPerPage", "sortBy", "descending", "cursor", "currentFilter"])
                );
                const mq = _.pick(
                    this.pagination,
                    ...(!this.cursor
                        ? ["page", "rowsPerPage", "sortBy", "descending", "total", "currentFilter"]
                        : ["rowsPerPage", "sortBy", "descending", "cursor", "currentFilter"])
                );

                if (!this.paginate) {
                    // q.rowsPerPage = -1;
                    mq.rowsPerPage = -1;
                    // q.page = 1;
                    mq.page = 1;
                }
                if (this.data.path && this.data.subpath) {
                    const session = ++this.session;
                    this.loading = true;
                    try {
                        const result = await runQuery(
                            this.data.path,
                            this.$feathers.service(this.data.path),
                            q,
                            this.data.filter,
                            this.data.select,
                            this.data.populate,
                            this.data.id,
                            this.data.invalid
                        );
                        if (this.session === session) {
                            this.fetchValue = result;
                        }
                    } finally {
                        if (this.session === session) this.loading = false;
                    }
                }
                if (this.data.static || !this.data.path || this.data.subpath) return (this.mpagination = pagination);
                if (process.server) return;
                if (_.isEqual(q, mq) && !force && this.source) return;
                if (this.data.query) {
                    const u = url.parse(window.location.toString());
                    const query = qs.parse(u.query) || {};
                    query[this.data.query] = _.merge(_.omit(query[this.data.query], "page", "rowsPerPage", "sortBy", "descending", "total"), q);
                    window.history.replaceState(null, null, url.resolve(this.$route.fullPath, "?" + qs.stringify(query)));
                }
                const session = ++this.session;
                this.loading = true;
                try {
                    const result = await runQuery(
                        this.data.path,
                        this.$feathers.service(this.data.path),
                        q,
                        this.data.filter,
                        this.data.select,
                        this.data.populate,
                        null,
                        this.data.invalid
                    );
                    if (this.session === session) {
                        this.mdata = result;
                        this.$emit("update:dataData", this.mdata);
                    }
                } finally {
                    if (this.session === session) this.loading = false;
                }
                if (this.data.prepend) {
                    this.mitems.splice(0, 0, ...this.data.prepend);
                }
                this.$emit("update:items", this.mitems);
            } finally {
                this._settingPagiation = null;
                this._settingPagiationTask = null;
            }
        },
        reload() {
            return this.setPagination(this.pagination, true);
        },
        invalidate() {
            this.pagination = {};
        },
        renderItem(_c, { item, header }) {
            const value = this.get(item, header);
            const link = this.getLink(item, header);

            let mitem;
            switch (header.type) {
                case "thumb":
                    mitem = _c("img", {
                        attrs: {
                            src: this.$imgHelper.thumb(value),
                        },
                        style: {
                            width: "50px",
                            padding: "1px",
                        },
                    });
                    break;
                case "thumbItem":
                    mitem = _c("img", {
                        attrs: {
                            src: this.$imgHelper.thumbURL(value),
                        },
                        style: {
                            width: "50px",
                            padding: "1px",
                        },
                    });
                    break;
                case "custom":
                    mitem = this._v((this.$props.extra || {})[value._id] || 0);
                    break;
                default:
                    mitem = this._v(this._s(value));
                    break;
            }

            return link
                ? _c(
                      "nuxt-link",
                      {
                          attrs: {
                              to: link,
                          },
                          staticClass: "primary--text",
                          style: "text-decoration: none !important",
                      },
                      [mitem]
                  )
                : mitem;
        },

        get(item, header, objectOnly) {
            const srcItem = header.computed ? this.getComputed(item) : item;
            let value = header.value ? _.get(srcItem, header.value) : srcItem;
            if (header.source) {
                const prefix = header.path + "_" || "";
                const sitem = this.fetchCache[prefix + value];
                if (sitem) {
                    value = header.path ? _.get(sitem, header.path || "name") : sitem;
                } else if (sitem === undefined) {
                    Vue.set(this.fetchCache, prefix + value, false);
                    if (!this.fetchItems) {
                        this.fetchItems = [];
                        if (!this.pendingsFetches) this.pendingsFetches = [];
                        const finalize = () => {
                            const idx = this.pendingsFetches.indexOf(fetchPromise);
                            idx !== -1 && this.pendingsFetches.splice(idx, 1);
                        };
                        const fetchPromise = (async () => {
                            await Vue.nextTick();
                            const f = this.fetchItems;
                            this.fetchItems = null;
                            const types = _.groupBy(f, (it) => it.prefix + it.source);
                            await Promise.all(
                                _.map(types, async (it, source) => {
                                    const prefix = it[0].prefix;
                                    const header = it[0].header;
                                    const service = this.$feathers.service(header.source instanceof Function ? header.source(item) : header.source);
                                    const itemKey = header.itemKey || "_id";
                                    const items = await service.find({
                                        query: {
                                            [itemKey]: {
                                                $in: it.map((i) => i.id),
                                            },
                                            ...header.filter,
                                            $limit: 100,
                                            $populate: header.populate,
                                            $select: header.select || (header.path !== null ? header.path || ["name"] : undefined),
                                            // $disableSoftDelete: true,
                                        },
                                    });
                                    _.each(items.data, (item) => {
                                        Vue.set(this.fetchCache, prefix + item[itemKey], item);
                                    });
                                })
                            );
                        })().then(finalize, finalize);
                        this.pendingsFetches.push(fetchPromise);
                    }
                    if (value) {
                        this.fetchItems.push({
                            source: header.source instanceof Function ? header.source(item) : header.source,
                            header,
                            prefix,
                            id: value,
                        });
                    }
                    if (objectOnly) return null;
                } else if (!sitem) {
                    if (objectOnly) return null;
                }
            }
            if (header.format) {
                const filter = Vue.filter(header.format);
                if (filter) {
                    value = filter(this, value, item, this.mvalue, header);
                } else {
                    console.warn(`Vue filter ${header.format} not found`);
                }
            }
            return value;
        },
        getLink(item, header) {
            if (header.noLink) return undefined;
            let value, source;
            if (header.linkSource) {
                value = _.get(item, header.linkValue);
                source = header.linkSource;
                source = source instanceof Function ? source(item) : source;
                if (!source) return undefined;
            } else if (header.source) {
                source = header.source instanceof Function ? header.source(item) : header.source;
                value = _.get(item, header.value);
                const itemKey = header.itemKey || "_id";
                if (value && typeof value === "object") value = value[itemKey];
                if (this.fetchCache[value] && this.fetchCache[value].deleted) {
                    return undefined;
                }
            } else return undefined;

            if (header.direct) {
                return `/${source}/edit/${value}`;
            }
            return `/${source}?${qs.stringify({ query: { editor: value } })}`;
        },
        getComputed(item) {
            if (this.compute) {
                if (this.compute instanceof Function) {
                    return this.compute(item);
                } else {
                    const obj = {};
                    Object.defineProperties(
                        obj,
                        _.mapValues(this.compute, (h) => ({
                            get() {
                                return h instanceof Function ? h(item) : h.get(item);
                            },
                            set(v) {
                                h.set(item, v);
                            },
                        }))
                    );
                    return obj;
                }
            }
            return {};
        },
        editItem(item, clone, assign) {
            this.editingOrigin = clone ? null : item;
            this.editingItem = _.merge({}, this.default instanceof Function ? this.default() : this.default, this.data.filter, item, assign);
            if (clone) {
                delete this.editingItem[this.mItemKey];
            }
        },
        addActionClick() {
            if (this.addAction) {
                this.editItem();
            }
        },
        searchActionClick() {
            if (this.searchAction) {
                this.beginSearch();
            }
        },
        exportActionClick() {
            if (this.exportAction) {
                this.exporting = true;
            }
        },
        importActionClick() {
            this.$emit('import')
        },
        refreshActionClick() {
            if (this.refreshAction) {
                this.reload();
            }
        },
        deleteItem(item) {
            this.deletingItem = item;
        },
        async deleteItemCore(item) {
            const mitem = item || this.deletingItem;
            this.deleteLoading = true;
            try {
                const service = this.data.path && this.$feathers.service(this.data.path);
                if (this.data.subpath) {
                    if (service) {
                        const item = await service.patch(this.data.id, {
                            $pull: {
                                [this.data.subpath]: {
                                    [this.mItemKey]: mitem[this.mItemKey],
                                },
                            },
                        });
                        _.each(item, (v, k) => Vue.set(this.mvalue, k, v));
                    } else {
                        const idx = this.mitems.findIndex((it) => it[this.mItemKey] === mitem[this.mItemKey]);
                        idx !== -1 && this.mitems.splice(idx, 1);
                    }
                } else {
                    if (service) {
                        await service.remove(mitem[this.mItemKey], { query: { ...(this.data.filter || {}) } });
                        const idx = this.mitems.findIndex((it) => mitem[this.mItemKey] === it[this.mItemKey]);
                        idx !== -1 && this.mitems.splice(idx, 1);
                    } else {
                        const idx = this.mitems.findIndex((it) => it[this.mItemKey] === mitem[this.mItemKey]);
                        idx !== -1 && this.mitems.splice(idx, 1);
                    }
                }
                const cur = _.get(mitem, this.mItemKey);
                const selected = this.mselected.find((it) => _.get(it, this.mItemKey) === cur);
                this.mselected = _.filter(this.mselected, (it) => it !== selected);

                this.$emit("update:items", this.mitems);
                this.deleting = false;
            } catch (e) {
                console.warn(e);
                this.$store.commit("SET_ERROR", e.message);
            } finally {
                this.deleteLoading = false;
            }
        },
        async save() {
            const service = this.data.path && this.$feathers.service(this.data.path);
            this.editLoading = true;
            try {
                if (this.data.subpath) {
                    if (service) {
                        const item = await service.patch(
                            this.data.id,
                            {
                                [this.editingItem[this.mItemKey] ? "$set" : "$push"]: {
                                    [this.data.subpath + (this.editingItem[this.mItemKey] ? ".$" : "")]: this.editingItem,
                                },
                            },
                            {
                                query: this.editingItem[this.mItemKey]
                                    ? {
                                          [this.data.subpath + "." + this.mItemKey]: this.editingItem[this.mItemKey],
                                      }
                                    : {},
                            }
                        );
                        _.each(item, (v, k) => Vue.set(this.mvalue, k, v));
                    } else {
                        if (this.editingItem[this.mItemKey]) {
                            this.$setPropHandler(this.editingOrigin, this.editingItem);
                        } else {
                            const item = {
                                ...this.editingItem,
                                [this.mItemKey]: uuid(),
                            };
                            this.mitems.push(item);
                        }
                    }
                } else {
                    if (service) {
                        if (this.editingItem[this.mItemKey]) {
                            const item = await service.patch(this.editingItem[this.mItemKey], this.editingItem, {
                                query: {
                                    ...(this.data.filter || {}),
                                    ...(this.data.populate ? { $populate: this.data.populate } : {}),
                                },
                            });
                            _.assign(this.editingItem, item);
                            this.$setPropHandler(this.editingOrigin, this.editingItem);
                        } else {
                            const item = await service.create(this.editingItem);
                            let result = item instanceof Array ? item : [item];
                            if (this.data.populate) {
                                const resp = await runQuery(
                                    this.data.path,
                                    this.$feathers.service(this.data.path),
                                    {},
                                    {
                                        ...this.data.filter,
                                        [this.mItemKey]: {
                                            $in: _.map(result, (it) => it[this.mItemKey]),
                                        },
                                    },
                                    this.data.select,
                                    this.data.populate,
                                    null,
                                    this.data.invalid
                                );
                                result = resp.data;
                            }
                            _.each(result, (item) => {
                                const oldItem = this.mitems.find((it) => it[this.mItemKey] === item[this.mItemKey]);
                                if (oldItem) _.assign(oldItem, item);
                                else this.mitems.push(item);
                            });
                        }
                    } else {
                        if (this.editingItem[this.mItemKey]) {
                            this.$setPropHandler(this.editingOrigin, this.editingItem);
                        } else {
                            const item = {
                                ...this.editingItem,
                                [this.mItemKey]: uuid(),
                            };
                            this.mitems.push(item);
                        }
                    }
                }

                this.editing = false;
                this.editingItem = null;
                this.editingOrigin = null;
            } catch (e) {
                console.warn(e);
                this.$store.commit("SET_ERROR", e.message);
            } finally {
                this.editLoading = false;
            }
        },

        setCursor(cursor) {
            this.pagination = {
                ...this.pagination,
                cursor,
            };
        },

        waitPending() {
            if (!this.pendingsFetches) return Promise.resolve();
            return Promise.all(this.pendingsFetches);
        },

        changeSort(header) {
            if (!header.sortable) return;
            const column = header.value;
            if (this.pagination.sortBy === column) {
                this.pagination = {
                    ...this.pagination,
                    descending: !this.pagination.descending,
                };
            } else {
                this.pagination = {
                    ...this.pagination,
                    sortBy: column,
                    descending: false,
                };
            }
        },

        toggleAll() {
            switch (this.selectState) {
                case "none":
                case "pageSome":
                case "some":
                    this.mselected = _.uniqBy(_.concat(this.mselected, this.mitems), (it) => _.get(it, this.mItemKey));
                    break;
                case "all":
                case "pageAll":
                    const dict = _.fromPairs(_.map(this.mitems, (it) => [_.get(it, this.mItemKey), true]));
                    this.mselected = _.filter(this.mselected, (it) => !dict[_.get(it, this.mItemKey)]);
                    break;
            }
        },

        toggleSelect(item) {
            const cur = _.get(item, this.mItemKey);
            const selected = this.mselected.find((it) => _.get(it, this.mItemKey) === cur);
            if (selected) {
                this.mselected = _.filter(this.mselected, (it) => it !== selected);
            } else {
                this.mselected = [...this.mselected, item];
            }
        },

        batchDelete() {
            this.batchDeleting = true;
        },

        async batchDeleteCore() {
            try {
                this.batchDeleteLoading = true;
                this.batchDeleteProgress = 0;
                const itemsToDelete = [...this.mselected];
                for (let i = 0; i < itemsToDelete.length; i++) {
                    await this.deleteItemCore(itemsToDelete[i]);
                    this.batchDeleteProgress = ((i + 1) / itemsToDelete.length) * 100;
                }
                this.batchDeleteProgress = 100;
                this.batchDeleting = false;
            } catch (e) {
                console.warn(e);
                this.$store.commit("SET_ERROR", e.message);
            } finally {
                this.batchDeleteLoading = false;
            }
        },

        getSearchComputed() {
            if (!this.searchFields) return {};
            const obj = {};

            const addSearchFilter = (id, sub, type) => {
                return {
                    get: () => {
                        const list = _.get(this.searchFilter, "$and") || [];
                        const it = list.find((it) => it.hasOwnProperty(id));

                        let val = it ? (sub ? _.get(it[id], sub) : it[id]) : undefined;

                        switch (type) {
                            case "regstart":
                            case "regexp":
                                val = _.get(val, "$regex") || "";
                                if (type === "regstart") val = (val || "").substr(1);
                                val = val.replace(/\\(.)/g, (r, c) => {
                                    return c;
                                });
                                break;
                            case "array":
                                val = val || [];
                                break;
                        }
                        return val;
                    },
                    set: (value) => {
                        switch (type) {
                            case "regstart":
                            case "regexp":
                                value = value
                                    ? {
                                          $regex: `${type === "regstart" ? "^" : ""}${escapeRegExp(value)}`,
                                          $options: "i",
                                      }
                                    : undefined;
                                break;
                            case "array":
                                value = value && value.length ? value : undefined;
                                break;
                        }
                        if (!this.searchFilter) this.searchFilter = {};
                        if (!this.searchFilter.$and) this.searchFilter.$and = [];
                        let list = this.searchFilter.$and;
                        const it = list.find((it) => it.hasOwnProperty(id));

                        if (it) {
                            if (sub) {
                                if (value) {
                                    Vue.set(it[id], sub, value);
                                } else {
                                    delete it[id][sub];
                                }
                                if (_.isEmpty(it[id])) {
                                    const idx = list.indexOf(it);
                                    idx !== -1 && list.splice(idx, 1);
                                }
                            } else {
                                if (value) it[id] = value;
                                else {
                                    const idx = list.indexOf(it);
                                    idx !== -1 && list.splice(idx, 1);
                                }
                            }
                        } else {
                            list.push({
                                [id]: sub ? _.set({}, sub, value) : value,
                            });
                        }
                    },
                };
            };

            _.each(this.searchFields, (v, id) => {
                if (typeof v === "object") {
                    const sobj = {};
                    _.each(v, (vv, sub) => {
                        Object.defineProperty(sobj, sub, addSearchFilter(id, sub, vv));
                    });
                    obj[id] = sobj;
                } else {
                    Object.defineProperty(obj, id, addSearchFilter(id, undefined, v));
                }
            });
            return obj;
        },
    },
};

export async function runQuery(path, service, query, filter, select, mpopulate, id, invalid) {
    const def = fieldDefs[path];
    const populate =
        (def &&
            def.populate &&
            _.map(def.populate, (v, k) => {
                if (typeof v === "string") v = { path: v };
                const mdef = fieldDefs[v.path];
                return {
                    path: k,
                    select: v.select || (mdef && mdef.customView && mdef.customView.name) || (mdef && mdef.fields) || "name",
                };
            })) ||
        mpopulate;
    if (id) {
        if (invalid) return null;
        return service.get(id, {
            query: {
                ...filter,
                $populate: populate,
                $select: select || undefined,
            },
        });
    } else {
        if (invalid) {
            return {
                data: [],
                total: 0,
                limit: query.rowsPerPage,
                skip: 0,
                sortBy: query.sortBy || "",
                descending: select || undefined,
            };
        }
        const customFilter = { ...(query.currentFilter || {}) };
        if (customFilter.$and && !customFilter.$and.length) {
            delete customFilter.$and;
        }
        const result = await service.find({
            query: {
                ...customFilter,
                $limit: query.rowsPerPage,
                $skip: query.rowsPerPage !== false ? query.page && (query.page - 1) * query.rowsPerPage : 0,
                $sort: (query.sortBy && { [query.sortBy]: query.descending ? -1 : 1 }) || undefined,
                ...filter,
                $populate: populate,
                $select: select || undefined,
                ...(query.cursor ? { $cursor: query.cursor } : {}),
            },
        });

        result.sortBy = query.sortBy || "";
        result.descending = query.descending || false;
        result.currentFilter = query.currentFilter || null;
        return result;
    }
}

export async function fetch(props, ctx, route) {
    const u = url.parse(route.fullPath);
    const query = (props.query && qs.parse(u.query)[props.query]) || {};

    if (query.sortBy === undefined && props.sortBy !== undefined) {
        query.descending = props.descending || false;
        query.sortBy = props.sortBy;
    }

    const result = await runQuery(
        props.path,
        ctx.$feathers.service(props.path),
        query,
        props.filter,
        props.select,
        props.populate,
        null,
        props.invalid
    );
    let editingItem;
    if (query.editor) {
        editingItem = await ctx.$feathers.service(props.path).get(query.editor);
    }
    return {
        path: props.path,
        query: props.query,
        data: result,
        name: props.name,
        select: props.select,
        populate: props.populate,
        filter: props.filter,
        editingItem,
        invalid: props.invalid,
        ...props.extra,
    };
}
function escapeRegExp(text) {
    return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
}
</script>

<style>
.mDialog {
    height: 90%;
}

.btn-toggle {
    display: flex;
    flex-wrap: wrap;
}
</style>

<style scoped>
tr:not(.datatable__expand-row) td {
    max-width: 200px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

tr:not(.datatable__expand-row) td:hover {
    overflow: visible;
}

tr:not(.datatable__expand-row) td > span {
    z-index: 1;
    transition: background 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
    will-change: background;
    padding-right: 12px;
    background: white;
}

tr:not(.datatable__expand-row):hover > td > span {
    background: #eee;
}

tr:not(.datatable__expand-row) td:hover > span {
    background: #eee;
    position: relative;
    z-index: 2;
}

tr:not(.datatable__expand-row) td:not(:nth-child(1)) {
    padding-left: 12px !important;
    padding-right: 0px !important;
}

.clickable {
    cursor: pointer;
}

.v-pagination {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-left: 4px;
    padding-right: 4px;
}

.v-pagination >>> li:first-child {
    margin-right: auto;
}

.v-pagination >>> li:last-child {
    margin-left: auto;
}

.fill-height .v-card__text,
.fill-height .ps {
    overflow: hidden;
}

.fill-height .ps {
    position: relative;
    margin: auto;
    width: 100%;
    height: 100%;
}

.v-toolbar >>> .v-toolbar__content {
    align-items: center;
    align-content: center;
}

.v-toolbar >>> .v-text-field {
    padding-top: 0px;
}

.ps >>> .v-table__overflow {
    overflow: initial;
}

.v-input >>> .v-input__control .v-input__slot {
    margin-bottom: 0;
}

.v-input--selection-controls {
    padding-top: 0;
}

.scroll-area {
    position: relative;
    margin: auto;
    width: 100%;
    height: 100%;
    flex: 1;
    flex-grow: 1;
}
</style>
