

import helper from '~/plugins/helper'
import _ from 'lodash'

export default helper('imgHelper', (ctx) => {
    const self = {
        hasImage(product, idx) {
            return product && product.images && (product.images[idx] || product.images[0]);
        },
        thumb(product, idx) {
            const thumb = product && (self.hasImage(product, idx) || product.image || product.media || (product.src && product));
            return self.thumbURL(thumb);
        },
        thumbURL(item, showDef) {
            if(!item || !item.thumb) return showDef ? require('~/assets/images/logo.png') : '';
            if(item.thumb.indexOf('data:') === 0) return item.thumb;
            else if(!item.mime) return 'data:image/png;base64,' + item.thumb.toString('base64');
            else return 'data:' + item.mime + ';base64,' + item.thumb.toString('base64');
        },

        fillFiles(item, images) {
            return _.isArray(item) ? _.map(item, val => {
                if(val && val.$image) return images[val.$image];
                else return self.fillFiles(val, images);
            }) : _.isObject(item) ? _.fromPairs(_.toPairs(item).map(([key, val]) => {
                if(val && val.$image) {
                    return [key, images[val.$image]];
                } else {
                    return [key, self.fillFiles(val, images)];
                }
            })) : item;
        },
    }

    return self;
    
});


