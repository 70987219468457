var render, staticRenderFns
import script from "./ObjectPicker.vue?vue&type=script&lang=js&"
export * from "./ObjectPicker.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/domorelabs/Documents/domorelabs/weather/weather-server/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!module.hot.data) {
      api.createRecord('de0b05f0', component.options)
    } else {
      api.reload('de0b05f0', component.options)
    }
    
  }
}
component.options.__file = "components/ObjectPicker.vue"
export default component.exports