var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    {
      directives: [
        {
          name: "action",
          rawName: "v-action.fullscreen",
          value: _vm.fullscreen,
          expression: "fullscreen",
          modifiers: { fullscreen: true }
        }
      ],
      ref: "app",
      class: { "fill-height": _vm.fullPage },
      staticStyle: { width: "100%" },
      attrs: { light: !_vm.dark, dark: _vm.dark },
      nativeOn: {
        touchmove: function($event) {
          return _vm.canMove($event)
        }
      }
    },
    [
      _c(
        "v-snackbar",
        {
          attrs: { top: "" },
          model: {
            value: _vm.error,
            callback: function($$v) {
              _vm.error = $$v
            },
            expression: "error"
          }
        },
        [
          _vm._v("\n        " + _vm._s(_vm.errorMessage) + "\n        "),
          _c("v-btn", {
            directives: [
              {
                name: "t",
                rawName: "v-t",
                value: "basic.close",
                expression: "'basic.close'"
              }
            ],
            attrs: { flat: "", color: "pink" },
            nativeOn: {
              click: function($event) {
                _vm.error = false
              }
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-navigation-drawer",
        {
          staticStyle: { padding: "0px !important" },
          attrs: {
            dark: "",
            persistent: "",
            height: "100%",
            width: 200,
            "mini-variant": _vm.mini,
            "enable-resize-watcher": "",
            app: ""
          },
          on: {
            "update:miniVariant": function($event) {
              _vm.mini = $event
            },
            "update:mini-variant": function($event) {
              _vm.mini = $event
            }
          },
          model: {
            value: _vm.drawer,
            callback: function($$v) {
              _vm.drawer = $$v
            },
            expression: "drawer"
          }
        },
        [
          _c(
            "v-layout",
            { attrs: { column: "", "fill-height": "" } },
            [
              _c("div", { class: ["app-logo", { mini: _vm.mini }] }, [
                _c("img", {
                  attrs: { src: require("assets/images/logo.png") }
                }),
                _vm._v(" "),
                !_vm.mini
                  ? _c("span", { staticClass: "app-name" }, [
                      _vm.testBanner
                        ? _c("span", [_vm._v("[TEST]")])
                        : _vm._e(),
                      _vm._v("Admin")
                    ])
                  : _vm._e()
              ]),
              _vm._v(" "),
              _c(
                "perfect-scrollbar",
                {
                  staticClass: "scroll-area",
                  attrs: { settings: { suppressScrollX: true } }
                },
                [
                  _c(
                    "v-list",
                    { attrs: { dense: "" } },
                    [
                      _vm._l(_vm.items, function(item, n) {
                        return [
                          item.items
                            ? _c(
                                "v-list-group",
                                {
                                  key: item.title,
                                  attrs: { group: item.group }
                                },
                                [
                                  _c(
                                    "v-list-tile",
                                    {
                                      attrs: {
                                        slot: "activator",
                                        ripple: "",
                                        disabled: item.disabled
                                      },
                                      slot: "activator"
                                    },
                                    [
                                      _c(
                                        "v-list-tile-action",
                                        [
                                          _c("v-icon", [
                                            _vm._v(_vm._s(item.action))
                                          ])
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-list-tile-content",
                                        [
                                          _c("v-list-tile-title", {
                                            directives: [
                                              {
                                                name: "t",
                                                rawName: "v-t",
                                                value: item.title,
                                                expression: "item.title"
                                              }
                                            ]
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _vm._l(item.items, function(subItem) {
                                    return [
                                      _c(
                                        "v-list-tile",
                                        {
                                          key: subItem.title,
                                          attrs: {
                                            to: !subItem.target && subItem.href,
                                            href:
                                              subItem.target && subItem.href,
                                            exact: subItem.exact,
                                            nuxt: !subItem.target,
                                            ripple: "",
                                            disabled:
                                              item.disabled || subItem.disabled,
                                            target: subItem.target
                                          }
                                        },
                                        [
                                          _c(
                                            "v-list-tile-action",
                                            [
                                              subItem.action
                                                ? _c(
                                                    "v-icon",
                                                    {
                                                      attrs: { color: "white" }
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(subItem.action)
                                                      )
                                                    ]
                                                  )
                                                : _c(
                                                    "svg",
                                                    {
                                                      staticClass: "mx-auto",
                                                      attrs: {
                                                        width: "25",
                                                        height: "25",
                                                        viewBox: "0 0 44 44",
                                                        fill: "none",
                                                        xmlns:
                                                          "http://www.w3.org/2000/svg"
                                                      }
                                                    },
                                                    [
                                                      _c("path", {
                                                        attrs: {
                                                          d:
                                                            "M1 -1.04907e-06L1 16C1 20.4183 4.58172 24 9 24L25 24",
                                                          stroke: "#E0E0E0"
                                                        }
                                                      })
                                                    ]
                                                  )
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c("v-list-tile-content", {
                                            directives: [
                                              {
                                                name: "t",
                                                rawName: "v-t",
                                                value: subItem.title,
                                                expression: "subItem.title"
                                              }
                                            ]
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  })
                                ],
                                2
                              )
                            : item.header
                            ? _c("v-subheader", {
                                directives: [
                                  {
                                    name: "t",
                                    rawName: "v-t",
                                    value: item.header,
                                    expression: "item.header"
                                  }
                                ],
                                key: n
                              })
                            : item.divider
                            ? _c("v-divider", {
                                key: "d" + n,
                                attrs: { light: "" }
                              })
                            : [
                                _c(
                                  "v-list-tile",
                                  {
                                    key: item.title,
                                    attrs: {
                                      disabled: item.disabled,
                                      to: item.href,
                                      exact: item.exact,
                                      nuxt: "",
                                      ripple: ""
                                    }
                                  },
                                  [
                                    _c(
                                      "v-list-tile-action",
                                      [
                                        _c("v-icon", [
                                          _vm._v(_vm._s(item.action))
                                        ])
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "v-list-tile-content",
                                      [
                                        _c("v-list-tile-title", {
                                          directives: [
                                            {
                                              name: "t",
                                              rawName: "v-t",
                                              value: item.title,
                                              expression: "item.title"
                                            }
                                          ]
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ]
                        ]
                      })
                    ],
                    2
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "justify-center px-2 pt-1",
                  staticStyle: { display: "flex", "align-items": "center" }
                },
                [
                  _c(
                    "v-menu",
                    {
                      attrs: { "offset-y": "" },
                      model: {
                        value: _vm.lang,
                        callback: function($$v) {
                          _vm.lang = $$v
                        },
                        expression: "lang"
                      }
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { slot: "activator", icon: "", dark: "" },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              $event.stopPropagation()
                              _vm.lang = !_vm.lang
                            }
                          },
                          slot: "activator"
                        },
                        [_c("v-icon", [_vm._v("language")])],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-list",
                        _vm._l(_vm.$store.state.locales, function(item) {
                          return _c(
                            "v-list-tile",
                            {
                              key: item.id,
                              attrs: {
                                value: _vm.$store.state.locale === item.id
                              },
                              on: {
                                click: function($event) {
                                  return _vm.$store.dispatch("changeLang", {
                                    id: item.id,
                                    $i18n: _vm.$i18n
                                  })
                                }
                              }
                            },
                            [
                              _c("v-list-tile-title", [
                                _vm._v(_vm._s(item.name))
                              ])
                            ],
                            1
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      !_vm.$store.state.fullscreen
        ? _c(
            "v-toolbar",
            { attrs: { dark: _vm.dark, fixed: "", app: "" } },
            [
              _c("v-toolbar-side-icon", {
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                    return _vm.toggleDrawer()
                  }
                }
              }),
              _vm._v(" "),
              _c("v-toolbar-title", {
                directives: [
                  {
                    name: "td",
                    rawName: "v-td",
                    value: {
                      path: _vm.$store.state.title,
                      args: _vm.$store.getters.margs
                    },
                    expression:
                      "{ path: $store.state.title, args: $store.getters.margs }"
                  }
                ]
              }),
              _vm._v(" "),
              _c("v-spacer"),
              _vm._v(" "),
              _vm._l(_vm.$store.state.actions, function(action) {
                return _c(
                  "v-fade-transition",
                  { key: action.action },
                  [
                    _c(
                      "v-btn",
                      {
                        attrs: {
                          icon: "",
                          to: action.to || undefined,
                          disabled: !!_vm.$store.state.processing
                        },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            $event.stopPropagation()
                            action.action &&
                              _vm.$root.$emit(action.action, action)
                          }
                        }
                      },
                      [
                        action.action !== _vm.$store.state.processing
                          ? _c("v-icon", [_vm._v(_vm._s(action.icon))])
                          : _c("v-progress-circular", {
                              attrs: { indeterminate: "", color: "blue" }
                            })
                      ],
                      1
                    )
                  ],
                  1
                )
              })
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "v-content",
        {
          class: { "fill-height": _vm.fullPage },
          staticStyle: { "overflow-y": "auto" }
        },
        [
          _c(
            "v-container",
            { staticStyle: { "min-height": "100%" }, attrs: { fluid: "" } },
            [_c("nuxt")],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-fab-transition",
        [
          _vm.$store.state.fullscreen
            ? _c(
                "v-btn",
                {
                  attrs: {
                    dark: "",
                    fab: "",
                    fixed: "",
                    bottom: "",
                    right: ""
                  },
                  on: { click: _vm.exitFullscreen }
                },
                [_c("v-icon", [_vm._v("fullscreen_exit")])],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }