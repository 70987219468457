<template>
    <section class="container">
        <!--img src="~assets/img/logo.png" alt="Nuxt.js Logo" class="logo" width="100" /-->
        <h1 class="title">
            {{ error.statusCode }}
        </h1>
        <h2 class="info">
            {{ error.message }}
        </h2>
        <nuxt-link class="button" to="/" v-if="error.statusCode === 404">Homepage</nuxt-link>
    </section>
</template>
<script>
export default {
    props: ['error'],
    created() {
        this.$store.commit('SET_TITLE', "Error");
    }
}
</script>

<style scoped>
.title {
    margin-top: 15px;
    font-size: 5em;
}

.info {
    font-weight: 300;
    color: #9aabb1;
    background-color: transparent !important;
    margin: 0;
}

.button {
    margin-top: 50px;
}
</style>
