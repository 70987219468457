var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-menu",
    {
      ref: "menu",
      attrs: {
        lazy: "",
        "close-on-content-click": false,
        transition: "scale-transition",
        "offset-y": "",
        "full-width": "",
        "nudge-right": 40,
        "min-width": "290px",
        "return-value": _vm.dateString
      },
      on: {
        "update:returnValue": function($event) {
          _vm.dateString = $event
        },
        "update:return-value": function($event) {
          _vm.dateString = $event
        }
      },
      model: {
        value: _vm.menu,
        callback: function($$v) {
          _vm.menu = $$v
        },
        expression: "menu"
      }
    },
    [
      _c("v-text-field", {
        attrs: {
          slot: "activator",
          label: _vm.label,
          "prepend-icon": "event",
          readonly: "",
          clearable: _vm.clearable
        },
        slot: "activator",
        model: {
          value: _vm.dateString,
          callback: function($$v) {
            _vm.dateString = $$v
          },
          expression: "dateString"
        }
      }),
      _vm._v(" "),
      _c(
        "v-card",
        [
          _c(
            "v-card-text",
            [
              _c(
                "v-layout",
                { attrs: { "fill-height": "" } },
                [
                  _c(
                    "v-flex",
                    [
                      _c(
                        "v-date-picker",
                        {
                          attrs: { scrollable: "" },
                          model: {
                            value: _vm.date,
                            callback: function($$v) {
                              _vm.date = $$v
                            },
                            expression: "date"
                          }
                        },
                        [_c("v-spacer")],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.dateTime
                    ? _c(
                        "v-flex",
                        [
                          _c("v-time-picker", {
                            attrs: { scrollable: "" },
                            model: {
                              value: _vm.time,
                              callback: function($$v) {
                                _vm.time = $$v
                              },
                              expression: "time"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-card-actions",
            [
              _c(
                "v-btn",
                {
                  attrs: { flat: "", color: "primary" },
                  on: {
                    click: function($event) {
                      _vm.menu = false
                    }
                  }
                },
                [_vm._v("Cancel")]
              ),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  attrs: { flat: "", color: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.$refs.menu.save(_vm.dateString)
                    }
                  }
                },
                [_vm._v("OK")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }