<template>
    <v-menu open-on-click :close-on-content-click="false">
        <template v-slot:activator="{ on }">
            <component
                :is="multiLine ? 'v-textarea' : 'v-text-field'"
                v-on="on"
                :value="$td(translate)"
                :label="$td(label)"
                :prepend-icon="getIcon($store.state.locale)"
            />
        </template>

        <v-card>
            <v-card-text>
                <div v-for="(locale, id) in $store.state.locales" :key="id">
                    <div>{{locale.name}}</div>
                    <component
                        :is="editor?'quill-editor':multiLine ? 'v-textarea' : 'v-text-field'"
                        hide-details
                        :prepend-icon="getIcon(id)"
                        :value="getTranslate(id)"
                        @input="setTranslate(id, $event)"
                        :class="{'mb-2':editor}"
                    />
                </div>
            </v-card-text>
        </v-card>
    </v-menu>
</template>

<script>
import _ from "lodash";
import { VTextarea, VTextField } from "vuetify/lib";
import quillEditor from "~/components/quillEditor";

export default {
    components: {
        VTextarea,
        VTextField,
        quillEditor,
    },
    props: {
        label: {},
        value: {},
        fallback: {},
        multiLine: { type: Boolean, default: false },
        editor: { type: Boolean, default: false },
    },
    computed: {
        translate() {
            if (typeof this.value === "string") {
                return [
                    {
                        lang: this.$store.state.locale,
                        value: this.value,
                    },
                ];
            } else if (this.value && !_.isArray(this.value)) {
                return _.map(this.value, (v, k) => ({
                    lang: k,
                    value: v,
                }));
            } else if (!this.value) {
                return [];
            }
            return this.value;
        },
    },
    mounted() {
        if (
            this.fallback &&
            (!this.value || !_.isArray(this.value) || _.size(this.value) === 0)
        ) {
            this.$emit("input", [
                {
                    lang: this.$store.state.locale,
                    value: this.fallback,
                },
            ]);
        } else if (this.value && !_.isArray(this.value)) {
            this.$emit("input", this.translate);
        }
    },
    methods: {
        getIcon(icon) {
            switch (icon) {
                case "en":
                    return "- flag-icon flag-icon-us";
                case "cht":
                    return "- flag-icon flag-icon-hk";
                case "chs":
                    return "- flag-icon flag-icon-cn";
            }
        },

        setTranslate(lang, value) {
            const v = _.filter(this.translate, (v) => v.lang !== lang).concat([
                {
                    lang,
                    value,
                },
            ]);
            this.$emit("input", v);
            this.$emit(
                "update:fallback",
                _.get(v.filter((it) => it.lang === "en")[0] || v[0], "value") ||
                    ""
            );
        },

        getTranslate(lang) {
            return (
                (this.translate.find((it) => it.lang === lang) || {}).value ||
                ""
            );
        },
    },
};
</script>

<style scoped>
.menu {
    display: block !important;
}

.v-text-field {
    width: 100%;
}
</style>
