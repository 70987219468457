











import moment from "moment";

export default {
    props: {
        value: { type: Date },
        periodUnit: { type: String, default: "week" },
    },
    computed: {
        minDate() {
            return moment()
                .subtract(2, "year")
                .toDate();
        },
        maxDate() {
            return new Date();
        },
        prevDisabled() {
            let prev = moment(this.value)
                .subtract(1, this.periodUnit)
                .startOf(this.periodUnit);
            return prev.isSameOrBefore(this.minDate);
        },
        nextDisabled() {
            let next = moment(this.value)
                .add(1, this.periodUnit)
                .startOf(this.periodUnit);
            return next.isSameOrAfter(this.maxDate);
        },
    },
    methods: {
        changeDate(type: "add" | "subtract") {
            this.$emit(
                "input",
                moment(this.value)
                    [type](1, this.periodUnit)
                    .toDate()
            );
        },
        prevDate() {
            this.changeDate("subtract");
        },
        nextDate() {
            this.changeDate("add");
        },
    },
};
