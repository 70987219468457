<template>
    <section class="ps-container"
        :is="$props.tagname"
        @ps-scroll-y="scrollHanle"
        @ps-scroll-x="scrollHanle"
        @ps-scroll-up="scrollHanle"
        @ps-scroll-down="scrollHanle"
        @ps-scroll-left="scrollHanle"
        @ps-scroll-right="scrollHanle"
        @ps-y-reach-start="scrollHanle"
        @ps-y-reach-end="scrollHanle"
        @ps-x-reach-start="scrollHanle"
        @ps-x-reach-end="scrollHanle"
        v-ps-scroll="{settings, size}"
    >
        <slot></slot>
        <resize-sensor @resized="handleResize"></resize-sensor>
    </section>
</template>
<style src="perfect-scrollbar/css/perfect-scrollbar.css"></style>
<style>
.ps-container {
    position: relative;
}
</style>
<script>
const scrollBar = process.browser && (require('perfect-scrollbar').default);
import ResizeSensor from  '~/components/ResizeSensor'
import { setTimeout } from 'timers';

export default {
    name: 'vue-perfect-scrollbar',
    components: {ResizeSensor},
    props: {
        settings: {
            default: undefined
        },
        tagname: {
            type: String,
            default: "section"
        }
    },
    data() {
        return {
            ps: null,
            size: 0,
        }   
    },
    methods: {
        scrollHanle(evt) {
            this.$emit(evt.type, evt)
        },
        handleResize() {
            setTimeout(() => {
                this.size = this.$el.clientWidth
            }, 600);
        },
    },
    directives: {
        'ps-scroll': {
            bind(el, hook) {
                el.ps = new scrollBar(el, hook.value && hook.value.settings);
            },
            update(el, hook) {
                el.ps.destroy();
                el.ps = new scrollBar(el, hook.value && hook.value.settings);
            },
            unbind(el, hook) {
                el.ps.destroy();
                el.ps = null;
            }
        }
    }
}
</script>
