export default {
    type: 'bodyType',
    inherit: 'enum',
    editor: 'toggle',
    enum: [
        {
            id: 'form', 
            name: { $t: 'bodyType.form' },
        },
        {
            id: 'json', 
            name: { $t: 'bodyType.json' },
        },
    ]
}

