


import Vue from 'vue'

export default {
    props: {
        value: {},
        format: { type: String },
        args: { type: Array, default: () => ([])}
    },
    render(_c) {
        return this._v(Vue.filter(this.format)(this, this.value, ...this.args));
    }
};

