export default {
    type: 'methodType',
    inherit: 'enum',
    editor: 'toggle',
    enum: [
        {
            id: 'GET', 
            name: { $t: 'methodType.get' },
        },
        {
            id: 'POST', 
            name: { $t: 'methodType.POST' },
        },
        {
            id: 'PUT', 
            name: { $t: 'methodType.PUT' },
        },
    ]
}

