export default {
    type: 'gender',
    inherit: 'enum',
    editor: 'toggle',
    enum: [
        {
            id: 'male', 
            name: { $t: 'doctor.gender.male' },
        },
        {
            id: 'female', 
            name: { $t: 'doctor.gender.female' },
        },
    ]
}