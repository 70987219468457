var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "container" },
    [
      _c("h1", { staticClass: "title" }, [
        _vm._v("\n        " + _vm._s(_vm.error.statusCode) + "\n    ")
      ]),
      _vm._v(" "),
      _c("h2", { staticClass: "info" }, [
        _vm._v("\n        " + _vm._s(_vm.error.message) + "\n    ")
      ]),
      _vm._v(" "),
      _vm.error.statusCode === 404
        ? _c("nuxt-link", { staticClass: "button", attrs: { to: "/" } }, [
            _vm._v("Homepage")
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }