export default {
    type: 'adminType',
    inherit: 'enum',
    editor: 'toggle',
    enum: [
        {
            id: 'admin', 
            name: { $t: 'edit_admin.type.admin' },
        },
        {
            id: 'editor', 
            name: { $t: 'edit_admin.type.editor' },
        },
    ]
}

