export default {
    type: "periodUnit",
    inherit: "enum",
    editor: "toggle",
    enum: [
        {
            id: "day",
            name: { $t: "edit_basic.periodUnit.day" },
        },
        {
            id: "week",
            name: { $t: "edit_basic.periodUnit.week" },
        },
        {
            id: "month",
            name: { $t: "edit_basic.periodUnit.month" },
        },
        {
            id: "year",
            name: { $t: "edit_basic.periodUnit.year" },
        },
    ],
};
