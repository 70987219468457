export function mapProp(propName, fields) {
    return _.fromPairs(
        _.map(fields, field => {
            return [
                field,
                {
                    get: function() {
                        return this[propName] && this[propName][field];
                    },
                    set: function(val) {
                        this.$emit("input", {
                            [field]: val
                        });
                    }
                }
            ];
        })
    );
}

function fallbackCopyTextToClipboard(text) {
    var textArea = document.createElement("textarea");
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
        var successful = document.execCommand("copy");
        var msg = successful ? "successful" : "unsuccessful";
        return true;
        //console.log("Fallback: Copying text command was " + msg);
    } catch (err) {
        return false;
        //console.error("Fallback: Oops, unable to copy", err);
    } finally {
        textArea.remove();
    }
}

export async function copyTextToClipboard(text) {
    if (!navigator.clipboard) {
        return fallbackCopyTextToClipboard(text);
    }
    try {
        await navigator.clipboard.writeText(text);
    } catch(e) {
        return fallbackCopyTextToClipboard(text);
    }
}
