import 'source-map-support/register'

import Vue from 'vue'
import Vuetify from 'vuetify'

import moment from 'moment'
import Vue2Filters from 'vue2-filters'
// import DataTable from 'domore-table'
// Vue.use(DataTable);

// optional import of scroll behaviour
import { Buffer } from 'buffer';
import DataTable from '~/components/DataTable'
import DataTableEdit from '~/components/DataTableEdit'
import Formatter from '~/components/formatter'
import BForm from '~/components/BForm'
import DatePicker from '~/components/DatePicker'
import ObjectPicker from '~/components/ObjectPicker'
import ImagePicker from '~/components/ImagePicker'
import Uploader from '~/components/Uploader'
import ConstantSelect from '~/components/ConstantSelect'
import PerfectScrollbar from '~/components/PerfectScrollBar'
import SimpleList from '~/components/SimpleList'
import BBtn from '~/components/BBtn'
import PeriodPicker from '../components/PeriodPicker.vue'
import PeriodArrow from '../components/PeriodArrow.vue'
import TranslateBox from '~/components/TranslateBox'

import _, { isArray } from 'lodash'

Vue.component('b-form', BForm);
Vue.component('formatter', Formatter);
Vue.component('data-table', DataTable);
Vue.component('data-table-edit', DataTableEdit);
Vue.component('perfect-scrollbar', PerfectScrollbar);
Vue.component('date-picker', DatePicker);
Vue.component('object-picker', ObjectPicker);
Vue.component('image-picker', ImagePicker);
Vue.component('constant-select', ConstantSelect);
Vue.component('uploader', Uploader);
Vue.component('simple-list', SimpleList);
Vue.component('b-btn', BBtn);
Vue.component('translate-box', TranslateBox);
Vue.component('period-picker', PeriodPicker);
Vue.component('period-arrow', PeriodArrow);

Vue.use(Vuetify, {
    theme: {
        primary: '#3388FF',
        accent: '#ce93d8',
        secondary: '#424242',
        info: '#0D47A1',
        warning: '#ffb300',
        error: '#FF5252',
        success: '#2E7D32',
    }
});

Vue.filter('smartSize', (value, showSize = true, showUnit = true) => {
    let unit = 'B';
    if (value > 512) {
        value /= 1024;
        unit = 'KB';
        if (value > 512) {
            value /= 1024;
            unit = 'MB';
            if (value > 512) {
                value /= 1024;
                unit = 'GB';
                if (value > 512) {
                    value /= 1024;
                    unit = 'TB';
                }
            }
        }
        if (value < 1) value = Math.round(value * 100) / 100;
        else if (value < 10) value = Math.round(value * 10) / 10;
        else value = Math.floor(value);
    }
    if (showSize && showUnit) return value + ' ' + unit;
    else if (showSize) return '' + value;
    else if (showUnit) return unit;
})

Vue.filter('beautiMongo', (value) => {
    return new Buffer(value, 'hex').toString('base64').replace(/\+/g, '-').replace(/\//g, '_');
})

Vue.use(Vue2Filters)

export function setPropHandler(item, $event) {
    //console.log('set prop', item, $event);
    _.each($event, (v, k) => {
        let it = item[k];
        if(v && typeof v === 'object') {
            if(it && typeof it === 'object' && v.$replace === undefined) {
                //console.warn('go inner', JSON.stringify(it), JSON.stringify(v));
                setPropHandler(it, v);
            } else if(v.$replace !== undefined) {
                //console.warn('replace1', JSON.stringify(item), JSON.stringify(k), JSON.stringify(v.$replace));
                Vue.set(item, k, v.$replace);
            }
            else {
                //console.warn('set & go inner', JSON.stringify(item), JSON.stringify(k), JSON.stringify(v));
                Vue.set(item, k, it = {});
                setPropHandler(it, v);
            }
        } else {
            if(v && v.$replace) {
                //console.warn('replace2', JSON.stringify(item), JSON.stringify(k), JSON.stringify(v.$replace));
                Vue.set(item, k, v.$replace);
            } else {
                //console.warn('set', JSON.stringify(item), JSON.stringify(k), JSON.stringify(v));
                Vue.set(item, k, v);
            }
        }
    });
}

Vue.prototype.$setPropHandler = setPropHandler;

Vue.prototype.$toPath = function(path, val) {
    return _.reduceRight(('' + path).split('.'), (p, k) => ({ [k]: p }), val);
}

Vue.directive('focus', {
    inserted: function (el, binding) {
        if(binding.value) {
            var input = el.querySelector('input');
            input ? input.focus() : el.focus()
        }
    },
    update(el, binding, vnode, oldVnode) {
        if(binding.value && binding.value !== binding.oldValue) {
            var input = el.querySelector('input');
            input ? input.focus() : el.focus()
        }
    },
})


Vue.prototype.$copy = function(elem, text) {
    if(navigator.clipboard) {
        return navigator.clipboard.writeText(text);
    }
    else if(elem) {
        let el = elem.$el;
        if(el.name !== 'INPUT' || el.name !== 'TEXTAREA')
            el = el.querySelector('input') || el.querySelector('textarea');
        if(el) {
            el.focus();
            el.select();
            document.execCommand('copy');
        }
    }
}



Vue.directive('action', {
    bind (el, binding, vnode) {
        const root = vnode.context.$root;
        const router = vnode.context.$router;
        const store = vnode.context.$store;
        if(!el.handler) {
            el.handler = {};
        }
        _.each(binding.modifiers, (v, k) => {
            el.handler[k] = async (action) => {
                if (binding.value instanceof Function) {
                    store.commit('SET_PROCESSING', action && action.action);
                    try {
                        await binding.value();
                    } catch(e) {
                        store.commit('SET_ERROR', e.message);
                    } finally {
                        store.commit('SET_PROCESSING', null);
                    }
                } else {
                    router.push(binding.value);
                }
            }
            root.$on(k, el.handler[k])
        });
    },
    unbind (el, binding, vnode) {
        const root = vnode.context.$root;
        _.each(binding.modifiers, (v, k) => {
            root.$off(k, el.handler[k])
            delete el.handler[k];
        })
    }
})

Vue.directive('feathers', {
    bind (el, binding, vnode) {
        const feathers = vnode.context.$feathers;
        if(!el.fhandler) {
            el.fhandler = {};
        }
        _.each(binding.modifiers, (v, k) => {
            if(k === 'created' || k === 'patched' || k === 'removed' || k === 'updated') return;
            el.fhandler[k] = (data) => {
                if(binding.value) binding.value(data);
            }
            const s = feathers.service(k);
            if(binding.modifiers.created) s.on('created', el.fhandler[k]);
            if(binding.modifiers.patched) s.on('patched', el.fhandler[k]);
            if(binding.modifiers.removed) s.on('removed', el.fhandler[k]);
            if(binding.modifiers.updated) s.on('updated', el.fhandler[k]);
        });
    },
    unbind (el, binding, vnode) {
        const feathers = vnode.context.$feathers;
        _.each(binding.modifiers, (v, k) => {
            if(k === 'created' || k === 'patched' || k === 'removed' || k === 'updated') return;
            const s = feathers.service(k);
            if(binding.modifiers.created) s.removeListener('created', el.fhandler[k]);
            if(binding.modifiers.patched) s.removeListener('patched', el.fhandler[k]);
            if(binding.modifiers.removed) s.removeListener('removed', el.fhandler[k]);
            if(binding.modifiers.updated) s.removeListener('updated', el.fhandler[k]);
            delete el.fhandler[k];
        })
    }
});


Vue.directive('focus', {
    inserted: function (el, binding) {
        if(binding.value) {
            var input = el.querySelector('input');
            input ? input.focus() : el.focus()
        }
    },
    update(el, binding, vnode, oldVnode) {
        if(binding.value && binding.value !== binding.oldValue) {
            var input = el.querySelector('input');
            input ? input.focus() : el.focus()
        }
    },
})


Vue.directive('escape', {
    bind (el, binding) {
        el.$escape = () => {
            return binding.value;
        };
        if(!window.$escape) {
            window.$escape = (e) => {
                if(e.keyCode === 27) {
                    const h = window.$escape.handlers;
                    for(var i = h.length - 1; i >= 0; i--) {
                        if(h[i] && h[i]() && h[i]()(e)) {
                            e.stopPropagation();
                            e.preventDefault();
                            break;
                        }
                    }
                }
            };
            window.$escape.handlers = [];
            window.addEventListener('keydown', window.$escape);
        }
        window.$escape.handlers.push(el.$escape);
    },
    unbind(el, binding) {
        if(el.$escape) {
            if(window.$escape) {
                const h = window.$escape.handlers;
                const idx = h.indexOf(el.$escape);
                idx !== -1 && h.splice(idx, 1);
            }
            delete el.$escape;
        }
    }
})

Vue.filter('name', (ctx, value) => {
    return typeof value === 'string' ? value : value ? `${value.lastName} ${value.firstName}` : '';
})

Vue.filter('username', (ctx, value) => {
    if(!value) return ctx.$t('basic.no_user');
    if(typeof value === 'string') return value;
    return Vue.filter('name')(ctx, value.name) || value.email || value._id;
})

Vue.prototype.$moment = moment;


Vue.filter('moment', (ctx, value) => {
    ctx.$ensureI18N();
    return value ? (value instanceof moment ? value : moment(value)).locale(ctx.$store.state.locale).format('lll') : '-'
})

Vue.filter('momentiso', (ctx, value) => {
    ctx.$ensureI18N();
    return value ? (value instanceof moment ? value : moment(value)).locale(ctx.$store.state.locale).toISOString() : '-'
})

Vue.filter('date', (ctx, value) => {
    ctx.$ensureI18N();
    return value ? (value instanceof moment ? value : moment(value).utcOffset(0)).locale(ctx.$store.state.locale).format('ll') : '-'
})

Vue.filter('currency', function(ctx, price) {
    const num = price|0;
    const remain = ((price-num)*100)|0;
    const numStr = num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    const remainStr = _.padStart(remain.toString(), 2, '0');
    const priceStr = `${numStr}.${remainStr}`;

    const currency = ctx.$store.state.currency;
    return `${currency.symbol}${priceStr}`;
});

Vue.filter('join', function(ctx, items) {
    if(items instanceof Array) return items.join(', ');
    return items;
})

Vue.filter('totalQuantity', function(ctx, items) {
    return _.sumBy(items.inventories, it => it.quantity);
})

Vue.filter('enum', function(ctx, value, item, parent, header) {
    const type = ctx.$types.resolve(header.type);
    return ctx.$td((type && type.enumDict[value]) || value);
})

if(!Object.getOwnPropertyDescriptor(Vue.prototype, '$shop')) {
    Object.defineProperty(Vue.prototype, '$shop', {
        enumerable: false,
        get() {
            return this.$store.state.shop;
        }
    });
}

Vue.filter('t', (ctx, value) => {
    return ctx.$td(value);
})

Vue.filter('pushbool', (ctx, value) => {
    return value ? 
        ctx.$t('pages.notifications') : 
        ctx.$t('basic.email')
})

Vue.filter('length', (ctx, value) => {
    
    if (value && typeof value.length === 'number') {
        return value.length;
    } else {
        return undefined;
    }
        
})

Vue.filter("offline", (ctx, value) => {
    ctx.$ensureI18N();
    const now = moment();
    if (value === null) return "normal";
    const time = value && (value instanceof moment ? value : moment(value));
    const diff = time && now.diff(time, "minute");
    return diff ? (diff > 30 ? "> 30 minutes" : "normal") : "-";
});

Vue.filter("connection", (ctx, value) => {
    if (typeof value === "number") {
        if (value > 0) return "online";
    }
    if (typeof value === "boolean") {
        if (value) return "online";
    }
    return "offline";
});

Vue.directive('press', {
    bind (el, binding, vnode) {
        let p = el.$press = {
            mousedown(e) {
                if(p.igs) {
                    p.igs = false;
                    return;
                }
                p.cancel();
                p.int = setTimeout(p.long, 1000);
            },
            mouseup(e) {
                if(p.ige) {
                    p.ige = false;
                    return;
                }
                if(p.ig) {
                    p.cancel();

                    // prevent long hold event bubbling down to inner elements
                    window.addEventListener(
                        'click',
                        p.captureClick,
                        true
                    );
                    setTimeout(() => {
                        window.removeEventListener('click', p.captureClick, true);
                    }, 10);
                    return;
                }
                p.cancel();
                p.ic = true;
            },
            captureClick(e) {
                e.stopPropagation();
                e.preventDefault();
            },
            mouseleave(e) {
                p.cancel();
            },
            click(e) {
                if(p.ic) {
                    emit(vnode, 'shortpress');
                    p.cancel();
                }
            },
            touchstart(e) {
                p.igs = true;
                p.cancel();
                p.int = setTimeout(p.long, 1000);
                if (event.targetTouches.length === 1) {
                    p.clientX = event.targetTouches[0].clientX;
                    p.clientY = event.targetTouches[0].clientY;
                }
            },
            touchmove(e) {
                if(!p.igs) return;
                if (event.targetTouches.length === 1) {
                    const deltaX = Math.abs(event.targetTouches[0].clientX - p.clientX)
                    const deltaY = Math.abs(event.targetTouches[0].clientY - p.clientY)
                    if(deltaX > 20 || deltaY > 20) {
                        p.cancel();
                    }
                }
            },
            touchend(e) {
                p.ige = true;
                if(p.ig) {
                    p.cancel();
                    return;
                }
                p.cancel();
                p.ic = true;
            },
            long() {
                p.int = 0;
                p.ig = true;
                emit(vnode, 'longpress');
            },
            cancel() {
                if(p.int) {
                    clearTimeout(p.int);
                    p.int = 0;
                }
                p.ig = false;
                p.ic = false;
            },
            int: 0,
            ig: false,
            ic: false,
            clientX: 0,
            clientY: 0,
        }
        el.addEventListener('mousedown', p.mousedown);
        el.addEventListener('mouseup', p.mouseup);
        el.addEventListener('mouseleave', p.mouseleave);
        el.addEventListener('click', p.click);
        el.addEventListener('touchend', p.touchend);
        el.addEventListener('touchmove', p.touchmove, { passive: true});
        el.addEventListener('touchstart', p.touchstart);
    },
    unbind (el, binding, vnode) {
        let p = el.$press;
        if(p) {
            el.removeEventListener('mousedown', p.mousedown);
            el.removeEventListener('mouseup', p.mouseup);
            el.removeEventListener('mouseleave', p.mouseleave);
            el.removeEventListener('click', p.click);
            el.removeEventListener('touchend', p.touchend);
            el.addEventListener('touchmove', p.touchmove, { passive: true});
            el.removeEventListener('touchstart', p.touchstart);
            delete el.$press;
        }
    }
});