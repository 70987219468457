export default {
    type: 'newsStatus',
    inherit: 'enum',
    editor: 'toggle',
    enum: [
        {
            id: 'draft', 
            name: { $t: 'edit_campaigns.status.draft' },
        },
        {
            id: 'published', 
            name: { $t: 'edit_campaigns.status.published' },
        },
    ]
}

