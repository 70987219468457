<template>
    <v-menu ref="menu" lazy :close-on-content-click="false" v-model="menu" transition="scale-transition" offset-y full-width :nudge-right="40" min-width="290px" :return-value.sync="dateString">
        <v-text-field slot="activator" :label="label" v-model="dateString" prepend-icon="event" readonly :clearable="clearable"></v-text-field>
        <v-card>
            <v-card-text>
                <v-layout fill-height>
                    <v-flex>
                        <v-date-picker v-model="date" scrollable>
                            <v-spacer></v-spacer>
                        </v-date-picker>
                    </v-flex>
                    <v-flex v-if="dateTime">
                        <v-time-picker v-model="time" scrollable>
                        </v-time-picker>
                    </v-flex>
                </v-layout>
            </v-card-text>
            <v-card-actions>
                <v-btn flat color="primary" @click="menu = false">Cancel</v-btn>
                <v-btn flat color="primary" @click="$refs.menu.save(dateString)">OK</v-btn>
            </v-card-actions>
        </v-card>
    </v-menu>
</template>

<script>
import moment from "moment";

export default {
    props: {
        value: {},
        label: {},
        dateTime: { type: Boolean, default: false },
        utc: { type: Boolean, default: false },
        clearable: { type: Boolean, default: false },
    },
    data() {
        return {
            menu: false
        };
    },
    computed: {
        mutc() {
            return !this.dateTime || this.utc;
        },
        formatString() {
            return this.dateTime ? 'YYYY-MM-DD HH:mm:ss' : 'YYYY-MM-DD';
        },
        dateString: {
            get() {
                return this.value
                    ? (this.mutc ? moment(this.value).utcOffset(0) : moment(this.value)).format(this.formatString)
                    : "";
            },
            set(val) {
                this.$emit("input", val ? (this.mutc ? moment.utc(val, this.formatString) : moment(val, this.formatString)).toISOString() : null);
            }
        },
        date: {
            get() {
                return this.value
                    ? (this.mutc ? moment(this.value).utcOffset(0) : moment(this.value)).format("YYYY-MM-DD")
                    : "";
            },
            set(val) {
                const t = this.dateTime ? `${val} ${this.time}` : val;
                this.$emit("input", val ? (this.mutc ? moment.utc(t, this.formatString) : moment(t, this.formatString)).toISOString() : null);
            }
        },
        time: {
            get() {
                return this.value
                    ? (this.mutc ? moment(this.value).utcOffset(0) : moment(this.value)).format("HH:mm:ss")
                    : "";
            },
            set(val) {
                const t = `${this.date} ${val}`;
                this.$emit("input", val ? (this.mutc ? moment.utc(t, this.formatString) : moment(t, this.formatString)).toISOString() : null);
            }
        }
    }
};
</script>