<template>
    <v-app
        v-action.fullscreen="fullscreen"
        :light="!dark"
        :dark="dark"
        ref="app"
        style="width: 100%;"
        @touchmove.native="canMove($event)"
        :class="{ 'fill-height': fullPage }"
    >
        <v-snackbar top v-model="error">
            {{ errorMessage }}
            <v-btn flat color="pink" @click.native="error = false" v-t="'basic.close'"></v-btn>
        </v-snackbar>

        <v-navigation-drawer
            dark
            persistent
            height="100%"
            style="padding: 0px !important"
            :width="200"
            v-model="drawer"
            :mini-variant.sync="mini"
            enable-resize-watcher
            app
        >
            <v-layout column fill-height>
                <div :class="['app-logo', { mini: mini }]">
                    <img src="~/assets/images/logo.png" />
                    <span v-if="!mini" class="app-name"><span v-if="testBanner">[TEST]</span>Admin</span>
                </div>
                <perfect-scrollbar class="scroll-area" :settings="{ suppressScrollX: true }">
                    <v-list dense>
                        <template v-for="(item, n) in items">
                            <v-list-group :key="item.title" v-if="item.items" :group="item.group">
                                <v-list-tile slot="activator" ripple :disabled="item.disabled">
                                    <v-list-tile-action>
                                        <v-icon>{{ item.action }}</v-icon>
                                    </v-list-tile-action>
                                    <v-list-tile-content>
                                        <v-list-tile-title v-t="item.title"></v-list-tile-title>
                                    </v-list-tile-content>
                                </v-list-tile>
                                <template v-for="subItem in item.items">
                                    <v-list-tile
                                        :key="subItem.title"
                                        :to="!subItem.target && subItem.href"
                                        :href="subItem.target && subItem.href"
                                        :exact="subItem.exact"
                                        :nuxt="!subItem.target"
                                        ripple
                                        :disabled="item.disabled || subItem.disabled"
                                        :target="subItem.target"
                                    >
                                        <v-list-tile-action>
                                            <v-icon v-if="subItem.action" color="white">{{ subItem.action }}</v-icon>
                                            <svg
                                                v-else
                                                class="mx-auto"
                                                width="25"
                                                height="25"
                                                viewBox="0 0 44 44"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path d="M1 -1.04907e-06L1 16C1 20.4183 4.58172 24 9 24L25 24" stroke="#E0E0E0" />
                                            </svg>
                                        </v-list-tile-action>
                                        <v-list-tile-content v-t="subItem.title"></v-list-tile-content>
                                    </v-list-tile>
                                </template>
                            </v-list-group>
                            <v-subheader :key="n" v-else-if="item.header" v-t="item.header"></v-subheader>
                            <v-divider :key="`d${n}`" v-else-if="item.divider" light></v-divider>
                            <template v-else>
                                <v-list-tile :disabled="item.disabled" :key="item.title" :to="item.href" :exact="item.exact" nuxt ripple>
                                    <v-list-tile-action>
                                        <v-icon>{{ item.action }}</v-icon>
                                    </v-list-tile-action>
                                    <v-list-tile-content>
                                        <v-list-tile-title v-t="item.title"></v-list-tile-title>
                                    </v-list-tile-content>
                                </v-list-tile>
                            </template>
                        </template>
                    </v-list>
                </perfect-scrollbar>
                <div style="display: flex; align-items: center;" class="justify-center px-2 pt-1">
                    <v-menu offset-y v-model="lang">
                        <v-btn slot="activator" icon dark @click.prevent.stop="lang = !lang">
                            <v-icon>language</v-icon>
                        </v-btn>
                        <v-list>
                            <v-list-tile
                                v-for="item in $store.state.locales"
                                :key="item.id"
                                :value="$store.state.locale === item.id"
                                @click="$store.dispatch('changeLang', { id: item.id, $i18n })"
                            >
                                <v-list-tile-title>{{ item.name }}</v-list-tile-title>
                            </v-list-tile>
                        </v-list>
                    </v-menu>
                </div>
            </v-layout>
        </v-navigation-drawer>
        <v-toolbar :dark="dark" v-if="!$store.state.fullscreen" fixed app>
            <v-toolbar-side-icon @click.stop="toggleDrawer()"></v-toolbar-side-icon>
            <v-toolbar-title v-td="{ path: $store.state.title, args: $store.getters.margs }"></v-toolbar-title>
            <v-spacer></v-spacer>
            <v-fade-transition v-for="action in $store.state.actions" :key="action.action">
                <v-btn
                    icon
                    :to="action.to || undefined"
                    :disabled="!!$store.state.processing"
                    @click.prevent.stop="action.action && $root.$emit(action.action, action)"
                >
                    <v-icon v-if="action.action !== $store.state.processing">{{ action.icon }}</v-icon>
                    <v-progress-circular indeterminate color="blue" v-else></v-progress-circular>
                </v-btn>
            </v-fade-transition>
        </v-toolbar>
        <v-content :class="{ 'fill-height': fullPage }" style="overflow-y:auto;">
            <v-container fluid style="min-height:100%;">
                <nuxt></nuxt>
            </v-container>
        </v-content>
        <v-fab-transition>
            <v-btn @click="exitFullscreen" v-if="$store.state.fullscreen" dark fab fixed bottom right>
                <v-icon>fullscreen_exit</v-icon>
            </v-btn>
        </v-fab-transition>
    </v-app>
</template>

<script>
import _ from "lodash";
import Vue from "vue";
import moment from "moment";
import { mapGetters } from "vuex";

export default {
    data() {
        return {
            drawer: false,
            mini: false,
            lang: false,
            testBanner: TEST_BANNER,
        };
    },
    head() {
        return {
            htmlAttrs: {
                style: this.fullPage ? "overflow: hidden; height: 100%;" : "overflow: auto;",
            },
            bodyAttrs: {
                style: this.fullPage ? "overflow: hidden; height: 100%;" : "overflow: auto;",
            },
        };
    },
    computed: {
        fullPage() {
            return this.$store.state.fullPage;
        },
        mfullscreen() {
            return this.$store.state.fullscreen;
        },
        items() {
            return this.$store.getters["auth/isAuthenticated"]
                ? [
                      {
                          href: "/stat",
                          title: "pages.home",
                          action: "home",
                          exact: true,
                      },
                      {
                          href: "/weatherApiCount",
                          title: "pages.weatherApiCount",
                          action: "fas fa-plug",
                      },
                      {
                          href: "/appUsers",
                          title: "pages.appUsers",
                          action: "fas fa-user",
                      },
                      {
                          href: "/weatherDevices",
                          title: "pages.weatherDevices",
                          action: "fas fa-tablet-alt",
                      },
                      {
                          href: "/notifications",
                          title: "pages.notifications",
                          action: "far fa-envelope",
                      },
                      {
                          href: "/alertSettings",
                          title: "pages.alertSettings",
                          action: "fas fa-bell",
                      },

                      ...(this.$store.getters["auth/isAdmin"]
                          ? [
                                {
                                    title: "pages.debugTools",
                                    action: "fas fa-bug",
                                    items: [
                                        {
                                            href: "/debugLogs",
                                            title: "pages.debugLogs",
                                        },
                                        {
                                            href: "/debugAnalyses",
                                            title: "pages.debugAnalyses",
                                        },
                                        {
                                            href: "/weatherRanges",
                                            title: "pages.weatherRanges",
                                            action: "fas fa-cloud",
                                        },
                                    ],
                                },

                                {
                                    title: "pages.faqs",
                                    action: "fas fa-question",
                                    items: [
                                        {
                                            href: "/faqCategory",
                                            title: "pages.faqCategory",
                                        },
                                        {
                                            href: "/faqs",
                                            title: "pages.faqs",
                                        },
                                    ],
                                },
                                {
                                    title: "pages.helps",
                                    action: "fas fa-info-circle",
                                    items: [
                                        {
                                            href: "/helpCategory",
                                            title: "pages.helpCategory",
                                            action: "fas fa-info-circle",
                                        },
                                        {
                                            href: "/helps",
                                            title: "pages.helps",
                                            action: "fas fa-info-circle",
                                        },
                                    ],
                                },
                                {
                                    title: "pages.contents",
                                    action: "fas fa-file",
                                    items: [
                                        {
                                            href: "/contentCategory",
                                            title: "pages.contentCategory",
                                        },
                                        {
                                            href: "/contents",
                                            title: "pages.contents",
                                        },
                                    ],
                                },

                                {
                                    title: "pages.subscriptions",
                                    action: "fas fa-info-circle",
                                    items: [
                                        {
                                            href: "/deviceSubscriptionPlan",
                                            title: "pages.deviceSubscriptionPlan",
                                            action: "fas fa-info-circle",
                                        },
                                        {
                                            href: "/subscriptionPlan",
                                            title: "pages.subscriptionPlan",
                                            action: "fas fa-info-circle",
                                        },

                                        {
                                            href: "/subscriptionOrder",
                                            title: "pages.subscriptionOrder",
                                            action: "fas fa-info-circle",
                                        },
                                    ],
                                },

                                {
                                    title: "pages.membership",
                                    action: "fas fa-user",
                                    items: [
                                        {
                                            href: "/userTypes",
                                            title: "pages.userTypes",
                                            action: "fas fa-tablet-alt",
                                        },
                                        {
                                            href: "/userTrials",
                                            title: "pages.userTrials",
                                            action: "fas fa-user",
                                        },
                                        {
                                            href: "/membershipSubscriptions",
                                            title: "pages.membershipSubscriptions",
                                            action: "fas fa-user",
                                        },
                                    ],
                                },

                                {
                                    href: "/uploadTemplates",
                                    title: "pages.uploadTemplates",
                                    action: "far fa-clipboard",
                                },
                                {
                                    href: "/popupNotification",
                                    title: "pages.popupNotification",
                                    action: "fas fa-comment",
                                },
                                {
                                    href: "/otas",
                                    title: "pages.otas",
                                    action: "fas fa-upload",
                                },

                                {
                                    href: "/admins",
                                    title: "pages.admins",
                                    action: "fas fa-lock",
                                },
                            ]
                          : []),
                      {
                          href: "/profile",
                          title: "pages.profile.$",
                          action: "supervised_user_circle",
                          exact: false,
                      },
                      {
                          href: "/logout",
                          title: "pages.logout",
                          action: "exit_to_app",
                      },
                  ]
                : [
                      {
                          href: "/login",
                          title: "pages.login",
                          action: "vpn_key",
                          exact: false,
                      },
                  ];
        },
        dark() {
            return this.$store.state.dark;
        },

        error: {
            get() {
                return !!this.$store.state.error;
            },
            set(v) {
                if (!v) {
                    this.$store.commit("SET_ERROR");
                }
            },
        },

        errorMessage() {
            return this.$store.state.error;
        },
        ...mapGetters(["attributes"]),
    },
    components: {},

    mounted() {
        this.handleLoad();

        window.addEventListener("unload", this.handleUnload, false);
        document.addEventListener("fullscreenchange", this.fullscreenChange);
        document.addEventListener("webkitFullscreenchange", this.fullscreenChange);
        document.addEventListener("gesturestart", this.gesture);

        this.updateFullPage();
    },
    beforeDestroy() {
        window.removeEventListener("unload", this.handleUnload, false);
        document.removeEventListener("fullscreenchange", this.fullscreenChange);
        document.removeEventListener("webkitFullscreenchange", this.fullscreenChange);
        document.removeEventListener("gesturestart", this.gesture);
    },
    methods: {
        gesture(e) {
            e.preventDefault();
        },

        handleUnload() {
            if (window.localStorage) {
                window.localStorage["unloadFlag"] = new Date().getTime();
            }
        },
        handleLoad() {
            if (window.localStorage) {
                var time = Number(window.localStorage["unloadFlag"]);
                if (isNaN(time)) time = 0;
                const now = new Date().getTime();
                // more than 10 seconds since previous unload event must be a "Logout" event
                if (now - time > 10 * 1000) {
                    this.$router.push("/logout");
                } else {
                    console.log("refresh");
                }
                window.localStorage.removeItem("unloadFlag");
            }
        },
        fullscreen() {
            if (document.fullscreenElement) {
                if (document.exitFullscreen) {
                    document.exitFullscreen();
                    this.$store.commit("SET_FULLSCREEN", false);
                }
                return;
            }
            if (document.webkitFullscreenElement) {
                if (document.webkitFullscreenElement) {
                    document.webkitExitFullscreen();
                    this.$store.commit("SET_FULLSCREEN", false);
                }
            }
            if (!window.navigator.standalone) {
                const elem = this.$refs.app.$el;
                const rfq = elem.requestFullscreen || elem.webkitRequestFullscreen || elem.mozRequestFullScreen || elem.msRequestFullscreen;
                if (!rfq) return;
                rfq.call(elem);
            }
            this.$store.commit("SET_FULLSCREEN", true);
        },

        exitFullscreen() {
            if (document.fullscreenElement && document.exitFullscreen) document.exitFullscreen();
            if (document.webkitFullscreenElement && document.webkitExitFullscreen) document.webkitExitFullscreen();
            this.$store.commit("SET_FULLSCREEN", false);
        },

        fullscreenChange(event) {
            this.$store.commit("SET_FULLSCREEN", event.fullscreen);
        },

        canMove(event) {
            if (this.$store.state.fullPage) {
                event.stopPropagation();
                if (!this.hasScrollable(event.target)) {
                    event.preventDefault();
                }
            }
        },

        hasScrollable(target) {
            return target.classList.contains("scrollable") || (target.parentElement && this.hasScrollable(target.parentElement));
        },

        updateFullPage() {
            if (process.server) return;
            const nuxt = document.getElementById("__nuxt");
            if (this.fullPage) nuxt.classList.add("fill-height");
            else nuxt.classList.remove("fill-height");

            const layout = document.getElementById("__layout");
            if (this.fullPage) layout.classList.add("fill-height");
            else layout.classList.remove("fill-height");
        },

        toggleDrawer() {
            if (this.drawer) {
                this.mini = !this.mini;
            } else {
                this.drawer = true;
                this.mini = false;
            }
        },
    },
    watch: {
        fullPage(val) {
            this.updateFullPage();
        },
        mfullscreen(val) {
            if (val) this.drawer = false;
        },
        drawer(val) {
            if (val && this.mfullscreen) {
                Vue.nextTick(() => {
                    this.drawer = false;
                });
            }
        },
        dark(val) {
            if (val) {
                this.mini = true;
            } else {
                this.mini = false;
            }
        },
    },
};
</script>

<style>
.app-logo {
    height: 64px;
    display: flex;
    justify-content: left;
    align-items: center;
    transition: 0.5s all;
    padding-left: 8px;
    background: white;
}

.application.theme--dark .app-logo {
    background: #222222;
}

.app-logo.mini {
    justify-content: center;
    padding-left: 0px;
}

.app-logo img {
    width: 50px;
}

.app-logo.mini img {
    width: 32px;
}

.app-name {
    padding-left: 8px;
    color: black;
    font-size: 24px;
    text-rendering: optimizeLegibility !important;
    -webkit-font-smoothing: antialiased !important;
    -moz-osx-font-smoothing: grayscale;
}

.application.theme--dark .app-name {
    color: white;
}
</style>

<style scoped>
.v-toolbar {
    transition: 0.5s all;
}

.v-list__tile__action {
    justify-content: center;
    min-width: 28px;
    margin-right: 16px;
}

.v-list__tile__content ~ .v-list__tile__action:not(.v-list__tile__action--stack) {
    margin-right: 0px;
    margin-left: 16px;
    justify-content: center;
}

.scroll-area {
    position: relative;
    margin: auto;
    width: 100%;
    height: 100%;
    flex: 1;
    flex-grow: 1;
}
</style>
