<template>
    <div class="picker" :style="{
        width: mini ? '50px' :  '250px',
        height: mini ? '50px' : '150px',
    }">
        <img @click="pickImage" :src="thumb" :style="{
            width: '100%',
            height: '100%',
            'object-position': 'center',
            'object-fit': 'contain'
        }"/>
        <div class="floating" v-if="minfo && !minfo.complete">
            <v-progress-circular :value="minfo.progress * 100" color="blue"></v-progress-circular>
        </div>
        <div class="floatingLabel" v-if="label">
            {{label}}
        </div>
    </div>
</template>

<script>

import uuid from 'uuid/v4'

export default {
    props: {
        'url': { type: String, default: '' },
        'parent': { type: String, default: '' },
        'dir': { type: String, default: '' },
        value: {},
        mini: Boolean,
        defImage: String,
        attachment: Boolean,
        label: String,
    },

    data() {
        return {
            minfo: null,
        }
    },

    computed: {
        thumb() {
            if(this.value && this.value.thumb) {
                return `data:${this.value.mime};base64,${this.value.thumb}`;
            }
            return this.value && this.value.src || this.defImage || require('~/assets/images/logo.png');
        }
    },

    methods: {
        async pickImage() {
            const file = document.createElement('input');
            file.style.display = 'none';
            file.type = 'file';
            file.accept = this.type;
            document.body.append(file);
            file.click();
            await new Promise((resolve) => file.onchange = resolve);
            if (file.files.length == 0) return;
            const mfile = file.files[0];
            await this.uploadFile(mfile);
            file.remove();
        },

        async uploadFile(mfile) {
            var data = new FormData();
            data.append('file', mfile, mfile.name);

            const info = {
                name: mfile.name,
                size: mfile.size,
                mime: mfile.type,
                thumb: null,
                id: uuid(),
                success: false,
                complete: false,
                processing: true,
                error: null,
                progress: 0
            };
            this.minfo = info;

            try {
                const response = await this.$feathers.post(`attachments/upload/${this.dir || 'others'}/${this.parent || 'others'}`, data, {
                    onUploadProgress: (progressEvent) => {
                        info.progress = progressEvent.loaded / progressEvent.total;
                    }
                });

                const rinfo = (response.data || {}).info || {};
                _.assign(info, rinfo);
                info.success = true;
                info.complete = true;
                info.progress = 1;
                info.processing = false;
            } catch (e) {
                info.error = e.message;
                info.complete = true;
                info.processing = false;
            }

            if(this.attachment) {
                this.$emit('input', info);
            } else {
                this.$emit('input', {
                    type: 'attachment',
                    attachment: info._id,
                    width: info.width,
                    height: info.height,
                    thumb: info.thumb,
                    src: info.src,
                });
            }
        }
    }
}
</script>

<style scoped>
.picker {
    position: relative;
    cursor: pointer;
}
.floating, .floatingLabel {
    position: absolute;
    top: 0;
    left: 0;
}

.floating {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.floatingLabel {
    margin: 10px;
    padding: 5px;
    background: rgba(255,255,255,0.8);
    border-radius: 5px;
}
</style>
