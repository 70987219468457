

import Vue from 'vue'

export default (name, func) => {
    return (ctx) => {
        const axiosPlugin = {
            install() {
                if(Vue[`__nuxt_${name}_installed__`]) return;
                Vue[`__nuxt_${name}_installed__`] = true
                if (!Vue.prototype.hasOwnProperty('$' + name)) {
                    Object.defineProperty(Vue.prototype, '$' + name, {
                        get () {
                            return this.$root.$options['$' + name];
                        }
                    })
                }
            }
        }
        Vue.use(axiosPlugin)

        const { app, store } = ctx;
        app['$' + name] = store['$' + name] = ctx['$' + name] = func(ctx);
    }
}

