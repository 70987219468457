export default {
    type: 'adType',
    inherit: 'enum',
    editor: 'toggle',
    enum: [
        {
            id: 'fullscreen', 
            name: { $t: 'edit_ad.adType.fullscreen' },
        },
        {
            id: 'article', 
            name: { $t: 'edit_ad.adType.article' },
        },
        {
            id: 'list', 
            name: { $t: 'edit_ad.adType.list' },
        },
        {
            id: 'video', 
            name: { $t: 'edit_ad.adType.video' },
        },
        {
            id: 'footer', 
            name: { $t: 'edit_ad.adType.footer' },
        },
    ]
}

