export default {
    type: "membershipSubscriptionOrderStatus",
    inherit: "enum",
    editor: "toggle",
    enum: [
        {
            id: "pending",
            name: { $t: "edit_membershipSubscriptions.status.pending" },
        },
        {
            id: "paid",
            name: { $t: "edit_membershipSubscriptions.status.paid" },
        },
        {
            id: "cancelled",
            name: { $t: "edit_membershipSubscriptions.status.cancelled" },
        },
        {
            id: "refunded",
            name: { $t: "edit_membershipSubscriptions.status.refunded" },
        },
        {
            id: "cancelPending",
            name: { $t: "edit_membershipSubscriptions.status.cancelPending" },
        },
    ],
};
