export default {
    type: "userTrialStatus",
    inherit: "enum",
    editor: "toggle",
    enum: [
        {
            id: "active",
            name: { $t: "edit_userTrial.status.active" },
        },
        {
            id: "cancelled",
            name: { $t: "edit_userTrial.status.cancelled" },
        },
        {
            id: "expired",
            name: { $t: "edit_userTrial.status.expired" },
        },
    ],
};
