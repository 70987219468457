
import helper from '~/plugins/helper'
import _ from 'lodash'

export default helper('rules', (ctx) => {
    const app = ctx.app;
    return {
        required(value) {
            return value ? true : app.$t('format.required');
        },
        length(length) {
            return (value) => {
                return valie && value.length >= length ? true : app.$t('format.length', { length: length })
            }
        },
        url(value) {
            return /^[-a-zA-Z0-9_]{1,256}$/.test(value) ? true : app.$t('format.subdomain');
        },
        host(value) {
            return /^[-a-zA-Z0-9_\.]{1,256}$/.test(value) ? true : app.$t('format.host');
        }

    }
})

