export default {
    type: 'exportFormat',
    inherit: 'enum',
    editor: 'toggle',
    enum: [
        {
            id: 'csv', 
            name: { $t: 'datatable.export.format.csv' },
        },
        {
            id: 'xlsx', 
            name: { $t: 'datatable.export.format.xlsx' },
        },
    ]
}

