

import _ from 'lodash'
import moment from 'moment'
import cookie from 'js-cookie';
const expires = 365;

export const state = () => ({
    locales: {
        'en': { name: 'English', id: 'en', locale: 'en_US', code: 'en', region: 'US' },
        'chs': { name: '简体中文', id: 'chs', locale: 'zh_CN', code: 'zh', region: 'CN'  },
        'cht': { name: '繁體中文', id: 'cht', locale: 'zh_HK', code: 'zh', region: 'HK', regions: ['HK', 'TW'] },
        'de': { name: 'DEUTSCH', id: 'de', locale: 'de', code: 'de', region: 'DE'  },
        'fr': { name: 'Français', id: 'fr', locale: 'fr', code: 'fr', region: 'FR'  },
        'it': { name: 'Italiano', id: 'it', locale: 'it', code: 'it', region: 'IT'  },
        'es': { name: 'español', id: 'es', locale: 'es', code: 'es', region: 'ES'  },
        'nl': { name: 'Nederland', id: 'nl', locale: 'nl', code: 'nl', region: 'NL' },
        'cs': { name: 'čeština', id: 'cs', locale: 'cs', code: 'cs', region: 'cs' },
    },
    locale: 'cht',
    title: "Admin",
    args: {},
    actions: [],
    processing: null,
    shop: null,
    shops: [],
    fullscreen: false,
    fullPage: false,
    noDrawer: false,
    dark: false,
    currency: {
        symbol: '$',
    },
    error: '',
})


export const getters = {
    margs: state => {
        return _.mapValues(state.args, it => it && it.toString())
    },

    localeCode: state => {
        const item = state.locales[state.locale];
        return item.locale;
    },

    attributes: state => {
        if(!state.shop) return {};
        return state.shop.attributes || {};
    }
}

export const mutations = {
    SET_LANG(state, locale) {
        state.locale = locale
    },

    SET_TITLE(state, { title, actions, fullPage, args, noDrawer, dark }) {
        state.title = title || 'Admin';
        state.fullPage = fullPage || false;
        state.actions = actions || [];
        state.args = args || {};
        state.noDrawer = noDrawer || false;
        state.dark = dark || false;
    },

    SET_TITLE_ARGS(state, args) {
        state.args = args || {};
    },

    SET_PROCESSING(state, processing) {
        if (state.processing !== processing)
            state.processing = processing;
    },

    SET_FULLSCREEN(state, fullscreen) {
        state.fullscreen = fullscreen;
    },

    LOGOUT(state) {
        state.shop = null;;
        state.shops = [];
    },

    SET_ERROR(state, error) {
        state.error = error;
    },

}

const cookieName = 'locale';

function getLangFromRequest(req) {
    if (!req || !req.headers || !req.headers.cookie) return undefined;
    const jwtCookie = req.headers.cookie.split(';').find(c => c.trim().startsWith(`${cookieName}=`));
    if (!jwtCookie) return undefined;
    return jwtCookie.split('=')[1];
}

export const actions = {
    initLang({ state, dispatch }, { ctx }) {
        let locale = state.locale;
        const lang = (window.navigator).userLanguage || window.navigator.language;
        const l = _.filter(state.locales, l => l.code === lang)[0];
        if (l) locale = l.id;
        dispatch('changeLang', { id: cookie.get(cookieName) || locale, ctx, init: true });
    },

    async changeLang({ commit, state }, { id, i18n, $i18n, init }) {
        const item = state.locales[id];
        if (item) {
            if(!init) {
                cookie.set(cookieName, id, { expires });
            }
            commit('SET_LANG', id);
            if(i18n) i18n.locale = id;
            if($i18n) $i18n.locale = id;
            moment.locale(item.locale);
        }
    }



}

