import feathers from '@feathersjs/feathers'
import rest from '@feathersjs/rest-client'
import fio from '@feathersjs/socketio-client'
import axios from 'axios'
import socketio from 'socket.io-client'
import helper from '~/plugins/helper'
import authentication from '@feathersjs/authentication-client';
import storage from './ssr-storage';

const cookieName = 'feathers-jwt';

function getTokenFromRequest(req) {
    if (!req || !req.headers || !req.headers.cookie) return undefined;
    const jwtCookie = req.headers.cookie.split(';').find(c => c.trim().startsWith(`${cookieName}=`));
    if (!jwtCookie) return undefined;
    return jwtCookie.split('=')[1];
}

export default helper('feathers', (ctx) => {
    let baseURL = '';
    let baseHost = '';
    if (process.server || !PRODUCTION_MODE) {
        baseHost = BASE_URL;
    } else {
        baseHost = '';
    }

    baseURL = baseHost + "/api"

    let f;
    const req = ctx.req;
    const { store } = ctx;

    if (process.server) {
        f = feathers().configure(rest(baseURL).axios(axios.create({})))
            .configure(authentication({ storage }));
    } else {
        store.dispatch('initLang', { ctx });
        const socket = socketio(baseHost, { path: '/api/socket.io', transports: ['websocket'] });
        socket.on('disconnect', function() {
            f.authenticated = false;
            f.authState = false;
        })
        f = feathers().configure(fio(socket, { timeout: 30000 })).configure(authentication({ storage, timeout: 30000 }));
    }

    f.authenticated = false;
    f.authState = false;

    f.hooks({
        before: {
            async find(hook) {
                if(hook.params.query && hook.params.query.$auth) {
                    delete hook.params.query.$auth;
                    if(!await f.doAuth()) {
                        return;
                    }
                }
            },
            async create(hook) {
                const $auth = (hook.params.query && hook.params.query.$auth) || (hook.data && hook.data.$auth);
                if($auth) {
                    if(hook.params.query) delete hook.params.query.$auth;
                    if(hook.data) delete hook.data.$auth;
                    if(!await f.doAuth()) {
                        return;
                    }
                }
            },
            async update(hook) {
                const $auth = (hook.params.query && hook.params.query.$auth) || (hook.data && hook.data.$auth);
                if($auth) {
                    if(hook.params.query) delete hook.params.query.$auth;
                    if(hook.data) delete hook.data.$auth;
                    if(!await f.doAuth()) {
                        return;
                    }
                }
            },
            async patch(hook) {
                const $auth = (hook.params.query && hook.params.query.$auth) || (hook.data && hook.data.$auth);
                if($auth) {
                    if(hook.params.query) delete hook.params.query.$auth;
                    if(hook.data) delete hook.data.$auth;
                    if(!await f.doAuth()) {
                        return;
                    }
                }
            },
            async remove(hook) {
                if(hook.params.query && hook.params.query.$auth) {
                    if(hook.params.query) delete hook.params.query.$auth;
                    if(!await f.doAuth()) {
                        return;
                    }
                }
            }
        },
        error(hook) {
            //console.log('hook', hook);
            return hook;
        }
    })

    f.authenticated = false;
    let authing;
    f.doAuth = function () {
        if(f.authenticated) return f.authState;

        const accessToken = (process.server) ? getTokenFromRequest(req) :
            window.localStorage.getItem(cookieName);

        if (!accessToken) {
            //ctx.redirect('/login');
            f.authenticated = true;
            f.authState = false;
            return false;
        }

        return (authing || (authing = (async () => {
            try {
                await store.dispatch('auth/jwt', { accessToken, feathersClient: f });
                f.authenticated = true;
                f.authState = true;
                console.log('success'); 
                authing = null;
                return true;
            } catch(error) {
                console.warn(error);
                authing = null;
                if(error.code === 401) {
                    f.authenticated = true;
                    f.authState = false;
                    //ctx.redirect('/login');
                    return false;
                    //console.log('expired');
                }
            }
        })()));
    }

    const a = axios.create({ baseURL })

    f.post = function(url, data, params) {
        const accessToken = (process.server) ? getTokenFromRequest(req) :
            window.localStorage.getItem(cookieName);
        return a.post(`${url}?token=${encodeURIComponent(accessToken)}`, data, params);
    }

    return f;

})

