export default {
    type: 'campaignStatus',
    inherit: 'enum',
    editor: 'toggle',
    enum: [
        {
            id: 'draft', 
            name: { $t: 'edit_news.status.draft' },
        },
        {
            id: 'published', 
            name: { $t: 'edit_news.status.published' },
        },
    ]
}

