var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("a", {
        directives: [
          {
            name: "t",
            rawName: "v-t",
            value: "invoice.print",
            expression: "'invoice.print'"
          }
        ],
        staticClass: "print-button",
        attrs: { href: "javascript:window.print()" }
      }),
      _vm._v(" "),
      _c("nuxt")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }