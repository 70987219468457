<template>
    <v-card>
        <v-card-title>{{label}}</v-card-title>
        <v-card-text>
            <v-layout row align-content-center justify-center align-center v-for="(entry, idx) in inputValue" :key="idx">
                <div style="flex-grow: 1">
                    <div>{{idx + 1}}</div>
                    <slot name="item" :item="entry" :edit="{
                        get item() {
                            return entry
                        },
                        set item(v) {
                            inputValue =  [...inputValue.slice(0, idx), v, ...inputValue.slice(idx + 1)];
                        }
                    }"/>
                </div>
                <v-btn icon @click="inputValue = [...inputValue.slice(0, idx), ...inputValue.slice(idx + 1)]">
                    <v-icon>delete</v-icon>
                </v-btn>
            </v-layout>
        </v-card-text>
        <v-btn icon @click="inputValue ? (inputValue = [...inputValue, getDefault()]) : (inputValue = [getDefault()])"><v-icon>add</v-icon></v-btn>
    </v-card>
</template>

<script>

import _ from 'lodash'
import uuid from 'uuid'

export default {
    props: {
        label: {},
        value: {},
        default: {},
        itemKey: { default: 'id', type: String },
    },

    computed: {
        inputValue: {
            get() { return this.value },
            set(v) { this.$emit('input', v); }
        }
    },

    methods: {
        getDefault() {
            const v = this.default instanceof Function ? this.default() : this.default ? _.cloneDeep(this.default) : { };
            v[this.itemKey] = uuid();
            return v;
        }
    }
}

</script>