


export default {
    functional: true,
    render(_c, { props }) {
        return props.render ? props.render(_c, props) : this._e();
    }
};

