export default {
    userTypes: {
        fields: ["name.value"],
        view: {
            translate: true,
            name: ["name"],
        },
    },
    users: {
        fields: ["email", "name"],
        view: {
            name: ["email", "name"],
        },
    },
    appUsers: {
        fields: ["email", "name"],
        view: {
            name: ["email", "name"],
        },
    },
    weatherDevices: {
        fields: ["name"],
        view: {
            name: ["name", "mac"],
        },
    },
    faqCategory: {
        fields: ["name.value"],
        view: {
            translate: true,
            name: ["name"],
        },
    },
    helpCategory: {
        fields: ["name.value"],
        view: {
            translate: true,
            name: ["name"],
        },
    },
    contentCategory: {
        fields: ["name.value"],
        view: {
            translate: true,
            name: ["name"],
        },
    },
    subscriptionPlan: {
        fields: ["name.value"],
        view: {
            translate: true,
            name: ["name"],
        },
    },
};
