export default {
    type: "subscriptionPlanStatus",
    inherit: "enum",
    editor: "toggle",
    enum: [
        {
            id: "draft",
            name: { $t: "edit_news.status.draft" },
        },
        {
            id: "published",
            name: { $t: "edit_news.status.published" },
        },
        {
            id: "internal",
            name: { $t: "edit_subscriptionPlan.status.internal" },
        },
    ],
};
