<template>
    <v-menu
        ref="menu"
        :close-on-content-click="false"
        v-model="menu"
        transition="slide-y-transition"
        offset-y
        :nudge-left="$vuetify.breakpoint.smAndUp ? 416 : undefined"
        min-width="290px"
        :return-value.sync="date"
        :class="$vuetify.breakpoint.smAndUp ? 'mr-5' : ''"
    >
        <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" class="btn-text pb-0">
                <p class="pb-0 pr-2">{{ periodString }}</p>
                <v-icon class="greyIcon--text" size="20" center>fas fa-angle-down</v-icon>
            </v-btn>
        </template>

        <v-card>
            <v-layout row>
                <v-flex class="px-2 pt-2 mr-3" align-self="start" algin="start">
                    <v-date-picker v-if="periodUnit === 'week'" key="normalPicker" flat no-title v-model="date" :min="minDate" :max="maxDate" />
                    <v-date-picker
                        v-else-if="periodUnit === 'month'"
                        key="monthPicker"
                        type="month"
                        flat
                        no-title
                        v-model="date"
                        :min="minDate"
                        :max="maxDate"
                    />
                    <v-btn-toggle v-else v-model="yearIndex" class="btn-toggle">
                        <v-btn v-for="diff in [0, 1, 2]" :key="diff" class="btn-text subtitle-2">{{ new Date().getFullYear() - diff }}</v-btn>
                    </v-btn-toggle>
                </v-flex>
                <v-flex align-self="start" class="pr-2 pt-2">
                    <v-btn-toggle v-model="unitIndex" class="btn-toggle-vertical">
                        <v-btn v-for="item in periods" :key="item" class="btn-text">{{ item }}</v-btn>
                    </v-btn-toggle>
                </v-flex>
            </v-layout>
            <v-card-actions class="justify-space-between">
                <v-btn @click="closeMenu" no-gutters align="center" class="py-3 btn-text">
                    <v-icon left small>fas fa-times</v-icon>
                    <div class="subtitle-2 ">{{ $t("basic.close") }}</div>
                </v-btn>
                <v-btn @click="save" no-gutters align="center" class="py-3  btn-text">
                    <div class="subtitle-2 primary--text">{{ $t("basic.save") }}</div>
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-menu>
</template>

<script>
import moment from "moment";

export default {
    props: {
        value: { type: Date },
        label: {},
        local: { type: Boolean, default: false },
        periodUnit: { type: String, default: "week" },
    },
    data() {
        return {
            menu: false,
            periods: ["week", "month", "year"],
        };
    },
    methods: {
        closeMenu() {
            this.menu = false;
        },
        save() {
            this.$refs.menu && this.$refs.menu.save(this.date);
            this.menu = false;
        },
    },
    computed: {
        date: {
            get() {
                if (!this.value) return moment().format("YYYY-MM-DD");
                if (this.local) {
                    return moment(this.value).format("YYYY-MM-DD");
                } else {
                    return moment.parseZone(this.value).format("YYYY-MM-DD");
                }
            },
            set(val) {
                this.$emit(
                    "input",
                    val ? (this.local ? moment(val, "YYYY-MM-DD").toDate() : moment.parseZone(val, "YYYY-MM-DD").toDate()) : new Date()
                );
            },
        },
        unitIndex: {
            get() {
                return this.periods.indexOf(this.periodUnit) || 0;
            },
            set(val) {
                this.$emit("toggle-unit", this.periods[val] || "week");
            },
        },
        yearIndex: {
            get() {
                const twoYearsAgo = new Date().getFullYear() - 2;
                const diff = +moment(this.date).format("YYYY") - twoYearsAgo;
                return this.date ? 2 - diff : 0;
            },
            set(val) {
                const date = moment().subtract(val, "year");
                this.$emit("input", this.local ? moment(date, "YYYY-MM-DD").toDate() : moment.parseZone(date, "YYYY-MM-DD").toDate());
            },
        },
        minDate() {
            return moment()
                .subtract(2, "years")
                .format("YYYY-MM-DD");
        },
        maxDate() {
            return moment().format("YYYY-MM-DD");
        },
        periodString() {
            let start = moment(this.date);
            let date = moment(this.date);
            switch (this.periodUnit) {
                case "week":
                    date = date.day(6);
                    start = moment(date).subtract(6, "day");
                    break;
                case "month":
                    date = date.endOf("month");
                    start = start.startOf("month");
                    break;
                default:
                    date = date.endOf("year");
                    start = start.startOf("year");
                    break;
            }
            return `${start.format("YYYY-MM-DD")} - ${date.format("YYYY-MM-DD")} (${this.periodUnit})`;
        },
    },
};
</script>

<style scoped>
.btn-toggle-vertical {
    flex-direction: column;
    box-shadow: none !important;
}
.btn-toggle {
    box-shadow: none !important;
}
.btn-text {
    box-shadow: none !important;
    background-color: transparent !important;
    will-change: auto !important;
}
</style>
