export default {
    type: 'eventLocation',
    inherit: 'enum',
    editor: 'toggle',
    enum: [
        {
            id: 'hk', 
            name: { $t: 'eventLocation.hk' },
        },
        {
            id: 'canada', 
            name: { $t: 'eventLocation.canada' },
        },
    ]
}

