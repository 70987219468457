var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "picker",
      style: {
        width: _vm.mini ? "50px" : "250px",
        height: _vm.mini ? "50px" : "150px"
      }
    },
    [
      _c("img", {
        style: {
          width: "100%",
          height: "100%",
          "object-position": "center",
          "object-fit": "contain"
        },
        attrs: { src: _vm.thumb },
        on: { click: _vm.pickImage }
      }),
      _vm._v(" "),
      _vm.minfo && !_vm.minfo.complete
        ? _c(
            "div",
            { staticClass: "floating" },
            [
              _c("v-progress-circular", {
                attrs: { value: _vm.minfo.progress * 100, color: "blue" }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.label
        ? _c("div", { staticClass: "floatingLabel" }, [
            _vm._v("\n        " + _vm._s(_vm.label) + "\n    ")
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }