export default {
    type: "subscriptionOrderStatus",
    inherit: "enum",
    editor: "toggle",
    enum: [
        {
            id: "pending",
            name: { $t: "edit_subscriptionOrder.status.pending" },
        },
        {
            id: "done",
            name: { $t: "edit_subscriptionOrder.status.done" },
        },
        {
            id: "cancelled",
            name: { $t: "edit_subscriptionOrder.status.cancelled" },
        },
        {
            id: "refunded",
            name: { $t: "edit_subscriptionOrder.status.refunded" },
        },
        {
            id: "error",
            name: { $t: "edit_subscriptionOrder.status.error" },
        },
    ],
};
