export const state = () => ({
    user: null,
});

export const mutations = {
    SET_USER(state, user) {
        state.user = user || null;
    },
};

export const getters = {
    isAuthenticated(state) {
        return !!state.user;
    },

    isVerified(state) {
        return state.user && state.user.verified || false;
    },

    resetRequired(state) {
        return state.user && state.user.resetRequired || false;
    },

    id(state) {
        return state.user && state.user._id || null;
    },

    isAdmin(state) {
        return state.user && state.user.role === 'admin'
    }
};

import _ from 'lodash'

export const actions = {
    // jwt in headers after refresh
    async jwt({ commit, state, dispatch, rootState, getters }, { accessToken, feathersClient }) {
        //console.log('& accesstoken: ', accessToken);

        const response = await feathersClient.authenticate({ strategy: 'jwt', accessToken })
        const payload = await feathersClient.passport.verifyJWT(response.accessToken);
        if(process.server || payload.userId !== getters.id) {
            const user = await feathersClient.service('users').get(payload.userId)
            commit('SET_USER', user);  
        }
        //console.warn('& Done jwt', state.user, rootState.shop);
    },
    // login
    async login({ commit, dispatch }, { email, password, type, feathersClient }) {
        const response = await feathersClient.authenticate({ strategy: type || 'local', email, password })
        const payload = await feathersClient.passport.verifyJWT(response.accessToken)
        feathersClient.authenticated = true;
        feathersClient.authState = true;
        const user = await feathersClient.service('users').get(payload.userId);
        feathersClient.set('user', user);
        commit('SET_USER', user);
    },
    // logout  
    async logout({ commit }, { feathersClient }) {
        await feathersClient.logout()
        feathersClient.authState = false;
        feathersClient.set('user', null);
        commit('SET_USER', null);
    },
    async resend({state, commit}, {feathersClient}) {
        const resp = await feathersClient.service('users/resend').create({});
        commit('SET_USER', _.merge({}, state.user, resp));
    },
    async verify({state, commit}, {feathersClient, token}) {
        const resp = await feathersClient.service('users/verify').create({ token });
        commit('SET_USER', _.merge({}, state.user, resp));
    },
    async changePassword({state, commit}, {feathersClient, password, oldPassword}) {
        const resp = await feathersClient.service('users/changePassword').create({ password, oldPassword });
        commit('SET_USER', _.merge({}, state.user, resp));
    },
    async changeProfile({state, commit}, {feathersClient, data}) {
        const resp = await feathersClient.service('users').patch(state.user._id, { ...data, $auth: true });
        commit('SET_USER', _.merge({}, state.user, resp));
    },
    // create
    async create(store, { user, feathersClient }) {
        const currentUser = await feathersClient.service('users').create(user)
        await store.dispatch('auth/login', { email: currentUser.email, password: user.password, feathersClient }, { root: true });
    },

};