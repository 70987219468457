import cookie from 'js-cookie';

const expires = 365;

export default {
    getItem(key) {
        if (process.server) return;
        return window.localStorage.getItem(key) || cookie.get(key);
    },
    setItem(key, value) {
        if (process.server) return;
        window.localStorage.setItem(key, value);
        cookie.set(key, value, { expires });
    },
    removeItem(key) {
        if (process.server) return;
        window.localStorage.removeItem(key);
        cookie.remove(key);
    },
};