<template>
    <div class="quill-editor">
        <slot name="toolbar"></slot>
        <div ref="editor"></div>
    </div>
</template>

<script>
const Quill = process.server ? {} : require("quill/dist/quill");

import _ from 'lodash'

require("~/assets/quill/quill.snow.css");
export default {
    name: "quill-editor",
    data() {
        return {
            _content: "",
            defaultModules: {
                toolbar: [
                    ["bold", "italic", "underline", "strike"],
                    ["blockquote", "code-block"],
                    [{ header: 1 }, { header: 2 }],
                    [{ list: "ordered" }, { list: "bullet" }],
                    [{ script: "sub" }, { script: "super" }],
                    [{ indent: "-1" }, { indent: "+1" }],
                    [{ direction: "rtl" }],
                    [{ size: ["small", false, "large", "huge"] }],
                    [{ header: [1, 2, 3, 4, 5, 6, false] }],
                    [{ color: [] }, { background: [] }],
                    [{ font: [] }],
                    [{ align: [] }],
                    ["clean"],
                    ["link", "image", "video"]
                ]
            }
        };
    },
    props: {
        content: String,
        value: String,
        options: {
            type: Object,
            required: false,
            default() {
                return {};
            }
        }
    },
    mounted() {
        this.initialize();
    },
    beforeDestroy() {
        this.quill = null;
    },
    methods: {
        initialize() {
            this.applyLang();
            if (this.$el) {
                // options and instance
                var self = this;
                self.options.theme = self.options.theme || "snow";
                self.options.bounds = self.options.bounds || self.$el;
                self.options.modules = self.options.modules || self.defaultModules;
                self.options.modules.toolbar = self.options.modules.toolbar || self.defaultModules.toolbar;
                self.options.placeholder = self.placeholder;
                self.options.readOnly = self.options.readOnly !== undefined ? self.options.readOnly : false;
                self.options.modules.toolbar = self.options.modules.toolbar || defaultOptions.modules.toolbar;
                self.quill = new Quill(self.$refs.editor, self.options);

                // set editor content
                if (self.value || self.content) {
                    self.quill.pasteHTML(self.value || self.content);
                }

                // mark model as touched if editor lost focus
                self.quill.on("selection-change", range => {
                    if (!range) {
                        self.$emit("blur", self.quill);
                    } else {
                        self.$emit("focus", self.quill);
                    }
                });

                // update model if text changes
                self.quill.on("text-change", (delta, oldDelta, source) => {
                    var html = self.$refs.editor.children[0].innerHTML;
                    const text = self.quill.getText();
                    if (html === "<p><br></p>") html = "";
                    self._content = html;
                    self.$emit("input", self._content);
                    self.$emit("change", {
                        editor: self.quill,
                        html: html,
                        text: text
                    });
                });

                // emit ready
                self.$emit("ready", self.quill);
            }
        },

        applyLang() {
            _.forEach(this.lang, (v, k) => {
                document.documentElement.style.setProperty(k, `'${v}'`)
            })
        }
    },
    watch: {
        content(newVal, oldVal) {
            if (this.quill) {
                if (!!newVal && newVal !== this._content) {
                    this._content = newVal;
                    this.quill.pasteHTML(newVal);
                } else if (!newVal) {
                    this.quill.setText("");
                }
            }
        },
        value(newVal, oldVal) {
            if (this.quill) {
                if (newVal !== this._content) {
                    this._content = newVal;
                    this.quill.pasteHTML(newVal);
                } else if (!newVal) {
                    this.quill.setText("");
                }
            }
        },
        placeholder(val) {
            if(this.$el) {
                const editor = this.$el.getElementsByClassName('ql-editor')[0];
                if(editor) editor.setAttribute('data-placeholder', val);
            }
        },
        lang(val) {
            this.applyLang();
        },
    },
    computed: {
        placeholder() {
            return this.options.placeholder || this.$t("quill.placeholder");
        },
        lang() {
            return {
                '--ql-size-small': this.$t('quill.size.small'),
                '--ql-size-normal': this.$t('quill.size.normal'),
                '--ql-size-large': this.$t('quill.size.large'),
                '--ql-size-huge': this.$t('quill.size.huge'),
                
                '--ql-heading-1': this.$t('quill.heading.1'),
                '--ql-heading-2': this.$t('quill.heading.2'),
                '--ql-heading-3': this.$t('quill.heading.3'),
                '--ql-heading-4': this.$t('quill.heading.4'),
                '--ql-heading-5': this.$t('quill.heading.5'),
                '--ql-heading-6': this.$t('quill.heading.6'),
                '--ql-heading-normal': this.$t('quill.heading.normal'),
                
                '--ql-enter-video': this.$t('quill.enter-video'),
                '--ql-enter-formula': this.$t('quill.enter-formula'),
                '--ql-enter-link': this.$t('quill.enter-link'),
                
                '--ql-save': this.$t('basic.save'),
                '--ql-remove': this.$t('basic.remove'),
            }
        },
    }
};
</script>

<style>
.quill-editor img {
    max-width: 100%;
}
</style>
