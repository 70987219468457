import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'

const _2bbbc6d9 = () => interopDefault(import('../pages/admins/index.vue' /* webpackChunkName: "pages/admins/index" */))
const _4641a23d = () => interopDefault(import('../pages/alertSettings.vue' /* webpackChunkName: "pages/alertSettings" */))
const _c4482f2c = () => interopDefault(import('../pages/apiTest.vue' /* webpackChunkName: "pages/apiTest" */))
const _700d09fc = () => interopDefault(import('../pages/appUsers/index.vue' /* webpackChunkName: "pages/appUsers/index" */))
const _08b6f310 = () => interopDefault(import('../pages/contentCategory/index.vue' /* webpackChunkName: "pages/contentCategory/index" */))
const _425ade22 = () => interopDefault(import('../pages/contents/index.vue' /* webpackChunkName: "pages/contents/index" */))
const _0259a09c = () => interopDefault(import('../pages/dashboard.vue' /* webpackChunkName: "pages/dashboard" */))
const _0c7ff71e = () => interopDefault(import('../pages/debugAnalyses.vue' /* webpackChunkName: "pages/debugAnalyses" */))
const _2656fcc0 = () => interopDefault(import('../pages/debugLogs.vue' /* webpackChunkName: "pages/debugLogs" */))
const _2ff35631 = () => interopDefault(import('../pages/deviceSubscriptionPlan/index.vue' /* webpackChunkName: "pages/deviceSubscriptionPlan/index" */))
const _de43fa56 = () => interopDefault(import('../pages/faqCategory/index.vue' /* webpackChunkName: "pages/faqCategory/index" */))
const _39019332 = () => interopDefault(import('../pages/faqs/index.vue' /* webpackChunkName: "pages/faqs/index" */))
const _627755f4 = () => interopDefault(import('../pages/helpCategory/index.vue' /* webpackChunkName: "pages/helpCategory/index" */))
const _6eebf4f3 = () => interopDefault(import('../pages/helps/index.vue' /* webpackChunkName: "pages/helps/index" */))
const _3bbd4b6c = () => interopDefault(import('../pages/login/index.vue' /* webpackChunkName: "pages/login/index" */))
const _435ab45c = () => interopDefault(import('../pages/logout.vue' /* webpackChunkName: "pages/logout" */))
const _468d5361 = () => interopDefault(import('../pages/membershipSubscriptions/index.vue' /* webpackChunkName: "pages/membershipSubscriptions/index" */))
const _e717f1ee = () => interopDefault(import('../pages/notifications/index.vue' /* webpackChunkName: "pages/notifications/index" */))
const _3b1f6ba9 = () => interopDefault(import('../pages/otas.vue' /* webpackChunkName: "pages/otas" */))
const _3a9d97d0 = () => interopDefault(import('../pages/popupNotification/index.vue' /* webpackChunkName: "pages/popupNotification/index" */))
const _730d7b0a = () => interopDefault(import('../pages/profile/index.vue' /* webpackChunkName: "pages/profile/index" */))
const _4a9b92b4 = () => interopDefault(import('../pages/stat.vue' /* webpackChunkName: "pages/stat" */))
const _b519bfdc = () => interopDefault(import('../pages/subscriptionOrder/index.vue' /* webpackChunkName: "pages/subscriptionOrder/index" */))
const _67d6628a = () => interopDefault(import('../pages/subscriptionPlan/index.vue' /* webpackChunkName: "pages/subscriptionPlan/index" */))
const _47cd25e4 = () => interopDefault(import('../pages/test.vue' /* webpackChunkName: "pages/test" */))
const _6812ead9 = () => interopDefault(import('../pages/uploadTemplates/index.vue' /* webpackChunkName: "pages/uploadTemplates/index" */))
const _a55994c6 = () => interopDefault(import('../pages/userTrials/index.vue' /* webpackChunkName: "pages/userTrials/index" */))
const _e3edcde2 = () => interopDefault(import('../pages/userTypes/index.vue' /* webpackChunkName: "pages/userTypes/index" */))
const _b852f572 = () => interopDefault(import('../pages/weatherApiCount.vue' /* webpackChunkName: "pages/weatherApiCount" */))
const _22113444 = () => interopDefault(import('../pages/weatherDevices/index.vue' /* webpackChunkName: "pages/weatherDevices/index" */))
const _70fd4308 = () => interopDefault(import('../pages/weatherRanges.vue' /* webpackChunkName: "pages/weatherRanges" */))
const _16113a3a = () => interopDefault(import('../pages/profile/changePassword.vue' /* webpackChunkName: "pages/profile/changePassword" */))
const _1f6342de = () => interopDefault(import('../pages/profile/changeProfile.vue' /* webpackChunkName: "pages/profile/changeProfile" */))
const _43439d02 = () => interopDefault(import('../pages/contentCategory/edit/_id.vue' /* webpackChunkName: "pages/contentCategory/edit/_id" */))
const _0931ffa8 = () => interopDefault(import('../pages/contents/edit/_id.vue' /* webpackChunkName: "pages/contents/edit/_id" */))
const _0485c9b4 = () => interopDefault(import('../pages/deviceSubscriptionPlan/edit/_id.vue' /* webpackChunkName: "pages/deviceSubscriptionPlan/edit/_id" */))
const _4b0d7c02 = () => interopDefault(import('../pages/faqCategory/edit/_id.vue' /* webpackChunkName: "pages/faqCategory/edit/_id" */))
const _64ab9af6 = () => interopDefault(import('../pages/faqs/edit/_id.vue' /* webpackChunkName: "pages/faqs/edit/_id" */))
const _e6c53efa = () => interopDefault(import('../pages/helpCategory/edit/_id.vue' /* webpackChunkName: "pages/helpCategory/edit/_id" */))
const _03efed24 = () => interopDefault(import('../pages/helps/edit/_id.vue' /* webpackChunkName: "pages/helps/edit/_id" */))
const _279bcff6 = () => interopDefault(import('../pages/membershipSubscriptions/edit/_id.vue' /* webpackChunkName: "pages/membershipSubscriptions/edit/_id" */))
const _5abf1fdf = () => interopDefault(import('../pages/popupNotification/edit/_id.vue' /* webpackChunkName: "pages/popupNotification/edit/_id" */))
const _7fa2f9a5 = () => interopDefault(import('../pages/subscriptionOrder/edit/_id.vue' /* webpackChunkName: "pages/subscriptionOrder/edit/_id" */))
const _18c75e5c = () => interopDefault(import('../pages/subscriptionPlan/edit/_id.vue' /* webpackChunkName: "pages/subscriptionPlan/edit/_id" */))
const _20d1657e = () => interopDefault(import('../pages/uploadTemplates/edit/_id.vue' /* webpackChunkName: "pages/uploadTemplates/edit/_id" */))
const _14e4178c = () => interopDefault(import('../pages/userTrials/edit/_id.vue' /* webpackChunkName: "pages/userTrials/edit/_id" */))
const _77c5f8f0 = () => interopDefault(import('../pages/userTypes/edit/_id.vue' /* webpackChunkName: "pages/userTypes/edit/_id" */))
const _0e4451f4 = () => interopDefault(import('../pages/weatherDevices/view/_id.vue' /* webpackChunkName: "pages/weatherDevices/view/_id" */))
const _6aa55c20 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

Vue.use(Router)

if (process.client) {
  if ('scrollRestoration' in window.history) {
    window.history.scrollRestoration = 'manual'

    // reset scrollRestoration to auto when leaving page, allowing page reload
    // and back-navigation from other pages to use the browser to restore the
    // scrolling position.
    window.addEventListener('beforeunload', () => {
      window.history.scrollRestoration = 'auto'
    })

    // Setting scrollRestoration to manual again when returning to this page.
    window.addEventListener('load', () => {
      window.history.scrollRestoration = 'manual'
    })
  }
}
const scrollBehavior = function (to, from, savedPosition) {
  // if the returned position is falsy or an empty object,
  // will retain current scroll position.
  let position = false

  // if no children detected and scrollToTop is not explicitly disabled
  if (
    to.matched.length < 2 &&
    to.matched.every(r => r.components.default.options.scrollToTop !== false)
  ) {
    // scroll to the top of the page
    position = { x: 0, y: 0 }
  } else if (to.matched.some(r => r.components.default.options.scrollToTop)) {
    // if one of the children has scrollToTop option set to true
    position = { x: 0, y: 0 }
  }

  // savedPosition is only available for popstate navigations (back button)
  if (savedPosition) {
    position = savedPosition
  }

  return new Promise((resolve) => {
    // wait for the out transition to complete (if necessary)
    window.$nuxt.$once('triggerScroll', () => {
      // coords will be used if no selector is provided,
      // or if the selector didn't match any element.
      if (to.hash) {
        let hash = to.hash
        // CSS.escape() is not supported with IE and Edge.
        if (typeof window.CSS !== 'undefined' && typeof window.CSS.escape !== 'undefined') {
          hash = '#' + window.CSS.escape(hash.substr(1))
        }
        try {
          if (document.querySelector(hash)) {
            // scroll to anchor by returning the selector
            position = { selector: hash }
          }
        } catch (e) {
          console.warn('Failed to save scroll position. Please add CSS.escape() polyfill (https://github.com/mathiasbynens/CSS.escape).')
        }
      }
      resolve(position)
    })
  })
}

export function createRouter() {
  return new Router({
    mode: 'history',
    base: decodeURI('/'),
    linkActiveClass: 'nuxt-link-active',
    linkExactActiveClass: 'nuxt-link-exact-active',
    scrollBehavior,

    routes: [{
      path: "/admins",
      component: _2bbbc6d9,
      name: "admins"
    }, {
      path: "/alertSettings",
      component: _4641a23d,
      name: "alertSettings"
    }, {
      path: "/apiTest",
      component: _c4482f2c,
      name: "apiTest"
    }, {
      path: "/appUsers",
      component: _700d09fc,
      name: "appUsers"
    }, {
      path: "/contentCategory",
      component: _08b6f310,
      name: "contentCategory"
    }, {
      path: "/contents",
      component: _425ade22,
      name: "contents"
    }, {
      path: "/dashboard",
      component: _0259a09c,
      name: "dashboard"
    }, {
      path: "/debugAnalyses",
      component: _0c7ff71e,
      name: "debugAnalyses"
    }, {
      path: "/debugLogs",
      component: _2656fcc0,
      name: "debugLogs"
    }, {
      path: "/deviceSubscriptionPlan",
      component: _2ff35631,
      name: "deviceSubscriptionPlan"
    }, {
      path: "/faqCategory",
      component: _de43fa56,
      name: "faqCategory"
    }, {
      path: "/faqs",
      component: _39019332,
      name: "faqs"
    }, {
      path: "/helpCategory",
      component: _627755f4,
      name: "helpCategory"
    }, {
      path: "/helps",
      component: _6eebf4f3,
      name: "helps"
    }, {
      path: "/login",
      component: _3bbd4b6c,
      name: "login"
    }, {
      path: "/logout",
      component: _435ab45c,
      name: "logout"
    }, {
      path: "/membershipSubscriptions",
      component: _468d5361,
      name: "membershipSubscriptions"
    }, {
      path: "/notifications",
      component: _e717f1ee,
      name: "notifications"
    }, {
      path: "/otas",
      component: _3b1f6ba9,
      name: "otas"
    }, {
      path: "/popupNotification",
      component: _3a9d97d0,
      name: "popupNotification"
    }, {
      path: "/profile",
      component: _730d7b0a,
      name: "profile"
    }, {
      path: "/stat",
      component: _4a9b92b4,
      name: "stat"
    }, {
      path: "/subscriptionOrder",
      component: _b519bfdc,
      name: "subscriptionOrder"
    }, {
      path: "/subscriptionPlan",
      component: _67d6628a,
      name: "subscriptionPlan"
    }, {
      path: "/test",
      component: _47cd25e4,
      name: "test"
    }, {
      path: "/uploadTemplates",
      component: _6812ead9,
      name: "uploadTemplates"
    }, {
      path: "/userTrials",
      component: _a55994c6,
      name: "userTrials"
    }, {
      path: "/userTypes",
      component: _e3edcde2,
      name: "userTypes"
    }, {
      path: "/weatherApiCount",
      component: _b852f572,
      name: "weatherApiCount"
    }, {
      path: "/weatherDevices",
      component: _22113444,
      name: "weatherDevices"
    }, {
      path: "/weatherRanges",
      component: _70fd4308,
      name: "weatherRanges"
    }, {
      path: "/profile/changePassword",
      component: _16113a3a,
      name: "profile-changePassword"
    }, {
      path: "/profile/changeProfile",
      component: _1f6342de,
      name: "profile-changeProfile"
    }, {
      path: "/contentCategory/edit/:id?",
      component: _43439d02,
      name: "contentCategory-edit-id"
    }, {
      path: "/contents/edit/:id?",
      component: _0931ffa8,
      name: "contents-edit-id"
    }, {
      path: "/deviceSubscriptionPlan/edit/:id?",
      component: _0485c9b4,
      name: "deviceSubscriptionPlan-edit-id"
    }, {
      path: "/faqCategory/edit/:id?",
      component: _4b0d7c02,
      name: "faqCategory-edit-id"
    }, {
      path: "/faqs/edit/:id?",
      component: _64ab9af6,
      name: "faqs-edit-id"
    }, {
      path: "/helpCategory/edit/:id?",
      component: _e6c53efa,
      name: "helpCategory-edit-id"
    }, {
      path: "/helps/edit/:id?",
      component: _03efed24,
      name: "helps-edit-id"
    }, {
      path: "/membershipSubscriptions/edit/:id?",
      component: _279bcff6,
      name: "membershipSubscriptions-edit-id"
    }, {
      path: "/popupNotification/edit/:id?",
      component: _5abf1fdf,
      name: "popupNotification-edit-id"
    }, {
      path: "/subscriptionOrder/edit/:id?",
      component: _7fa2f9a5,
      name: "subscriptionOrder-edit-id"
    }, {
      path: "/subscriptionPlan/edit/:id?",
      component: _18c75e5c,
      name: "subscriptionPlan-edit-id"
    }, {
      path: "/uploadTemplates/edit/:id?",
      component: _20d1657e,
      name: "uploadTemplates-edit-id"
    }, {
      path: "/userTrials/edit/:id?",
      component: _14e4178c,
      name: "userTrials-edit-id"
    }, {
      path: "/userTypes/edit/:id?",
      component: _77c5f8f0,
      name: "userTypes-edit-id"
    }, {
      path: "/weatherDevices/view/:id?",
      component: _0e4451f4,
      name: "weatherDevices-view-id"
    }, {
      path: "/",
      component: _6aa55c20,
      name: "index"
    }],

    fallback: false
  })
}
