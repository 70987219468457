var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "d-flex", staticStyle: { gap: "1.5rem" } },
    [
      _c(
        "v-btn",
        {
          attrs: { icon: "", disabled: _vm.prevDisabled },
          on: { click: _vm.prevDate }
        },
        [
          _c("v-icon", { attrs: { small: "" } }, [
            _vm._v("fas fa-chevron-left")
          ])
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-btn",
        {
          attrs: { icon: "", disabled: _vm.nextDisabled },
          on: { click: _vm.nextDate }
        },
        [
          _c("v-icon", { attrs: { small: "" } }, [
            _vm._v("fas fa-chevron-right")
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }