import Vue from 'vue'
import VueI18n from 'vue-i18n'
import _ from 'lodash'
import moment from 'moment'
import axios from 'axios'


Vue.use(VueI18n)

export function bind(el, binding, vnode) {
    t(el, binding, vnode)
}

function localeEqual(el, vnode) {
    const vm = vnode.context
    return el._locale === vm.$i18n.locale
}

export function update(el, binding, vnode, oldVNode) {
    if (localeEqual(el, vnode) && _.isEqualWith(binding.value, binding.oldValue)) return;
    t(el, binding, vnode)
}

function t(el, binding, vnode) {
    let value = binding.value
    let result = '';

    const vm = vnode.context
    if (!vm) {
        warn('not exist Vue instance in VNode context')
        return
    }
    const i18n = vm.$i18n;
    if (!i18n) {
        warn('not exist VueI18n instance in Vue instance')
        return
    }

    if(value) {
        if(value.$join) {
            return _.map(value.$join, it => vm.$td(it)).join('');
        }
        let $ta = value.$ta;
        let $a = value.$a;
        if(value.path) {
            if(value.args) $ta = value.args;
            value = value.path;
        }

        if(typeof value === 'string') {
            result = value;
        } else if(_.isArray(value)) {
            let enValue, locValue, defValue;
            _.each(value, v => {
                if(v.lang === i18n.locale) locValue = v.value;
                if(v.lang === 'en') enValue = v.value;
                defValue = v.value;
            })
            result = locValue || enValue || defValue;
        } else if(typeof value === 'object') {
            if(value.$t) {
                if($ta) {
                    const args = {
                        ...$a,
                        ..._.mapValues($ta, it => vm.$td(it))
                    }
                    result = vm.$i18n.t(value.$t, args);
                } else if($a) {
                    result = vm.$i18n.t(value.$t, $a);
                } else {
                    result = i18n.t(value.$t);
                }
            } else {
                result = value[i18n.locale] || value['en'] || _.map(value, it => it)[0] || '';
            }
        }
    }

    el._vt = el.textContent = result;
    el._locale = i18n.locale
}

Vue.directive('td', {
    bind,
    update,
})

let i18NInit = false;

Vue.prototype.$ensureI18N = function() {
    if(process.browser) {
        if(i18NInit) return;
        moment.locale(this.$store.getters.localeCode);
        i18NInit = true;
    }
}

Vue.prototype.$td = function (item) {
    if (typeof item === 'string') return item;
    else if (!item) return '';

    const i18n = this.$root.$i18n;

    if(item.$join) {
        return _.map(item.$join, it => this.$td(it)).join('');
    }
    else if(item.$t) {
        if(item.$ta) {
            const args = {
                ...item.$a,
                ..._.mapValues(item.$ta, it => this.$td(it))
            }
            return i18n.t(item.$t, args);
        } else if(item.$a) {
            return i18n.t(item.$t, item.$a);
        } else {
            return i18n.t(item.$t);
        }
    } else if(_.isArray(item)) {
        let enValue, locValue, defValue;
        _.each(item, v => {
            if(v.lang === i18n.locale) locValue = v.value;
            if(v.lang === 'en') enValue = v.value;
            defValue = v.value;
        })
        return locValue || enValue || defValue;
    } 

    return item[i18n.locale] || item['en'] || _.map(item, it => it)[0] || '';
};

Vue.prototype.$tdf = function (item, fallback) {
    return (!_.isEmpty(item) && this.$td(item)) || fallback;
}

const i18n = ({ app, store }) => {
    const dict = {};
    app.i18n = new VueI18n({
        locale: store.state.locale,
        fallbackLocale: 'en',
        silentTranslationWarn: true,
        async missing(lang, key) {
          if(!PRODUCTION_MODE) {
                if(!dict[key]) {
                    dict[key] = true;
                    console.log("missing", {key, lang})
                    await axios.post(`${BASE_URL}/api/missingTranslations`, {
                        key
                    })
                }
            }
        },
        messages: {
            'cht': require('~/locales/cht.json'),
            'chs': require('~/locales/chs.json'),
            'en': require('~/locales/en.json'),
        }
    })

    app.$t = app.i18n.t.bind(app.i18n);

    if (module.hot) {
        module.hot.accept(['~/locales/cht.json', '~/locales/chs.json', '~/locales/en.json'], function () {
            app.i18n.setLocaleMessage('cht', require('~/locales/cht.json'))
            app.i18n.setLocaleMessage('chs', require('~/locales/chs.json'))
            app.i18n.setLocaleMessage('en', require('~/locales/en.json'))
        })
    }
};

export default i18n;
