export default {
    type: "membershipSubscriptionPaymentMethod",
    inherit: "enum",
    editor: "toggle",
    enum: [
        {
            id: "google_play",
            name: { $t: "edit_membershipSubscriptions.paymentMethod.google_play" },
        },
        {
            id: "app_store",
            name: { $t: "edit_membershipSubscriptions.paymentMethod.app_store" },
        },
    ],
};
